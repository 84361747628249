import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import GradeIcon from "@material-ui/icons/Grade";
import { FormattedMessage } from "react-intl";
import Rating from "@material-ui/lab/Rating";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "store/dialog";
import { useParams } from "react-router-dom";
import ContactForm from "./ContactForm";
import { Link, useRouteMatch } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  wrapper: {
    top: -1,
    zIndex: 8,
    [theme.breakpoints.down(1200)]: {
      width: "100% !important",
      position: "relative",
    },
  },
  cardhead: {
    [theme.breakpoints.down(1100)]: {
      display: "block !important",
      margin: "auto",
      textAlign: "center",
    },
  },
  card: {
    width: 350,
    minHeight: 400,
    boxShadow: "4px 4px 3px rgba(0, 97, 161, 0.15)",
    borderRadius: 4,
    position: "fixed",
    backgroundColor: "#f6f9fc",
    color: "#767676",
    padding: 20,
    [theme.breakpoints.down(1200)]: {
      display: "block",
      position: "relative",
      width: "100%",
      margin: "auto",
      padding: 0,
      minHeight: 330,
      paddingTop: 30,
    },
  },
  circleimg: {
    position: "relative",
    margin: "auto",
    [theme.breakpoints.down(1200)]: {
      left: 0,
      width: "50%",
      margin: "inherit",
    },
  },
  profileImg: {
    width: 160,
    height: 160,
    margin: "auto",
    borderRadius: "50%",
    boxShadow: "0 2px 4px 0px rgba(115, 166, 227, 0.4)",
    border: "3px solid #ffffff",
    [theme.breakpoints.down(1100)]: {
      width: 120,
      height: 120,
    },
  },
  price: {
    width: 80,
    height: 80,
    margin: "auto",
    backgroundColor: "#d50951",
    borderRadius: "50%",
    boxShadow: "0 2px 4px rgba(0, 97, 161, 0.86)",
    border: "3px solid #ffffff",
    position: "absolute",
    left: "60%",
    top: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 30,
    fontWeight: "bold",
    color: "white",
    [theme.breakpoints.down(1200)]: {
      right: 0,
      left: "30%",
      margin: "auto",
    },
    [theme.breakpoints.down(1100)]: {
      width: 70,
      height: 70,
      fontSize: 16,
      top: 50,
      left: "11vh",
    },
  },
  cardicons: {
    color: "#4cbbc2",
    width: 20,
    margin: "auto",
  },
  iconstxt: {
    fontSize: 10,
    marginTop: "0.5em",
    padding: 6,
  },
  reqBtn: {
    margin: "7px 0",
    backgroundColor: "#d50951",
    border: "2px solid #d50951",
    borderRadius: 4,
    padding: "0.5rem 1.5rem",
    color: "white",
    width: "100%",
    height: 40,
    fontWeight: 900,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#b8093e",
    },
    [theme.breakpoints.down(1200)]: {
      marginRight: 5,
    },
  },
  askBtn: {
    margin: "7px 0",
    backgroundColor: "transparent",
    border: "2px solid #545454",
    borderRadius: 4,
    padding: "0.5rem 1.5rem",
    color: "#545454",
    width: "100%",
    height: 40,
    fontWeight: 900,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#545454",
      color: "white",
    },
    [theme.breakpoints.down(1200)]: {
      marginLeft: 5,
    },
  },
  socials: {
    display: "flex",
  },
  about: {
    [theme.breakpoints.up(1200)]: {
      display: "none",
    },
    [theme.breakpoints.down(1200)]: {
      display: "none",
    },
  },
  buttons: {
    [theme.breakpoints.down(1200)]: {
      backgroundColor: "white",
      display: "flex",
      position: "fixed",
      bottom: 0,
      width: "100%",
    },
  },
  name: {
    fontSize: 40,
    fontWeight: "bold",
    color: "#232f49",
    [theme.breakpoints.down(1200)]: {
      fontSize: 25,
    },
  },
  star: {
    fontSize: 24,
    color: "#f8be15",
    [theme.breakpoints.down(1200)]: {
      fontSize: 20,
    },
  },
  slogan: {
    marginTop: "0.5em",
    fontSize: 20,
    fontWeight: 500,
    [theme.breakpoints.down(1200)]: {
      fontSize: 17,
    },
  },
  locate: {
    marginTop: "1.5em",
    fontSize: 15,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
  },
  locateicon: {
    width: 20,
    color: "#2373ff",
    marginRight: 12,
  },
  mobilesizeTxt: {
    display: "none",
    [theme.breakpoints.down(1200)]: {
      display: "block",
      marginTop: 50,
    },
  },
  dialogbox: {
    maxWidth: 1000,
  },
}));

const ProfileCard = () => {
  let slug = useParams();
  const match = useRouteMatch({ path: "/:language/:city" });
  const { language, city } = match.params;
  React.useEffect(() => { }, [city, language]);
  const Detail = useSelector((state) => state.Detail);
  const classes = useStyles();
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.currency);
  const Modal = (props) => {
    dispatch(
      openDialog({
        children: <ContactForm />,
        fullWidth: true,
        fullScreen: false,
        scroll: "body",
        classes: { paper: `m-0  ${classes.dialogbox}` },
      })
    );
  };
   
  return (
    <div className={`${classes.wrapper} w-2/6`}>
    
      {!Detail.data.data ? null : (
        <div className={classes.card}>
          <div className={`flex ${classes.cardhead}`}>
            <div className={classes.circleimg}>
              <img
                className={classes.profileImg}
                src={Detail.data.data.masterImage}
              />
              {Detail.data.data.PricePerHours === 0 ? (
                <div className={classes.price}>Free</div>
              ) : (
                <div className={classes.price}>
                  {currency.current.symbol}
                  {Detail.data.data.PricePerHours * currency.current.value}
                </div>
              )}
            </div>
            <div className={classes.mobilesizeTxt}>
              <h1 className={classes.name}>{Detail.data.data.masterName}</h1>
              <span>
                <Rating
                  precision={0.1}
                  value={Detail.data.data.masterRate}
                  className={classes.star}
                  readOnly
                />
              </span>
              <h4 className={classes.slogan}>
                {Detail.data.data.masterDescription}
              </h4>
            </div>
            <div className={classes.about}>
              <h1 className={classes.name}>Mahan S</h1>
              <span>
                <GradeIcon className={classes.star} />
                <GradeIcon className={classes.star} />
                <GradeIcon className={classes.star} />
                <GradeIcon className={classes.star} />
                <GradeIcon className={classes.star} />
              </span>
              <h4 className={classes.slogan}>
                Optometry graduate teaches maths, english and science
              </h4>
              <p className={classes.locate}>
                <svg
                  data-v-bdc48200=""
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="map-marker-alt"
                  role="img"
                  xmlns="https://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  className={classes.locateicon}
                >
                  <path
                    data-v-bdc48200=""
                    fill="currentColor"
                    d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                  ></path>
                </svg>
                {Detail.data.data.Citytitle}
              </p>
            </div>
          </div>
          <div className={classes.socials}>
            <div className="flex m-4 flex-1">
              <div className="text-center bg-white px-2 py-2 m-1  w-1/2">
                <svg
                  data-v-dd6933fc=""
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="clock"
                  role="img"
                  xmlns="https://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className={classes.cardicons}
                >
                  <path
                    data-v-dd6933fc=""
                    fill="currentColor"
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm57.1 350.1L224.9 294c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12v137.7l63.5 46.2c5.4 3.9 6.5 11.4 2.6 16.8l-28.2 38.8c-3.9 5.3-11.4 6.5-16.8 2.6z"
                  ></path>
                </svg>
                <p className={classes.iconstxt}>
                  69+
                  <FormattedMessage id="app.hour" defaultMessage="hrs taught" />
                </p>
              </div>
              <div className="text-center bg-white px-2 py-2 m-1  w-1/2">
                <svg
                  data-v-dd6933fc=""
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="paper-plane"
                  role="img"
                  xmlns="https://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className={classes.cardicons}
                >
                  <path
                    data-v-dd6933fc=""
                    fill="currentColor"
                    d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
                  ></path>
                </svg>
                <p className={classes.iconstxt}>
                  <FormattedMessage
                    id="app.replaies"
                    defaultMessage="replaies"
                  />
                  27
                  <FormattedMessage
                    id="restaurant.cart.Minute"
                    defaultMessage="minutes"
                  />
                </p>
              </div>
            </div>
          </div>
          <div className={classes.buttons}>
            <Button onClick={Modal} className={classes.reqBtn}>
              <FormattedMessage id="app.request" defaultMessage="Request" />
            </Button>
            <Link
              to={`/${language}/${city}/Suppliers/${slug.id}/AskQuestion/`}
            >
              <Button className={classes.askBtn}>
                <FormattedMessage id="app.Ask" defaultMessage="Ask" />
              </Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileCard;
