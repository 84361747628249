import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  dot: {
    width: 15,
    height: 15,
    margin: 20,
    borderRadius: "50%",
  },
}));
const NavLevel = () => {
  const classes = useStyles();
  const SubjectSearch = useSelector((state) => state.SubjectSearch);
  return (
    <nav className="sticky md:flex mt-32 w-1/4 justify-center hidden flex-col items-center">
      <div
        style={{
          backgroundColor: SubjectSearch.subjects ? "#db2b21" : "gray",
        }}
        className={classes.dot}
      ></div>
      <div
        style={{
          backgroundColor: SubjectSearch.level ? "#db2b21" : "gray",
        }}
        className={classes.dot}
      ></div>
      <div
        style={{
          backgroundColor: SubjectSearch.where ? "#db2b21" : "gray",
        }}
        className={classes.dot}
      ></div>
      <div
        style={{
          backgroundColor: SubjectSearch.week ? "#db2b21" : "gray",
        }}
        className={classes.dot}
      ></div>
    </nav>
  );
};

export default NavLevel;
