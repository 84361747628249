import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { lessonsforReqAction } from "store/requestLesson";
const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: "white",
    boxShadow: "0 2px 4px rgba(115, 166, 227, 0.4)",
    border: "1px solid #e2e2e2",
    color: "#232f49",
    width: 180,
    margin: "10px 0",
    height: 55,
    fontWeight: 900,
    textTransform: "capitalize",
  },
  active: {
    backgroundColor: "white",
    boxShadow: "0 2px 4px rgba(115, 166, 227, 0.4)",
    color: "#4cbcc2",
    width: 180,
    fontWeight: 900,
    margin: "10px 0",
    height: 55,
    border: "1px solid #4cbcc2",
    textTransform: "capitalize",
  },
}));
const LessonsFor = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [active, setactive] = useState("");
  const locationHandler = (item) => {
    setactive(item);
    dispatch(lessonsforReqAction(item))
  };
  return (
    <div className="mt-6">
      <h6 className="font-bold text-base text-gray-700">
        Who are the lessons for?
      </h6>
      <div className="flex">
        <Button
          onClick={() => locationHandler("Me")}
          className={active === "Me" ? classes.active : classes.box}
        >
          Me
        </Button>
        <Button
          onClick={() => locationHandler("Child or dependant")}
          className={`${
            active === "Child or dependant" ? classes.active : classes.box
          } mx-4`}
        >
          Child or dependant
        </Button>
      </div>
    </div>
  );
};

export default LessonsFor;
