import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import ScrollspyNav from "react-scrollspy-nav";
import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  mobilenavprofile: {
    backgroundColor: "#232f48",
    minHeight: 100,
    width: "100%",
    zIndex: 10,
    position: "fixed",
    display: "block",
    marginTop: -1,
    paddingTop: 20,
    paddingBottom: 5,
    top: -120,
    transition: "0.4s linear",
    "-moz-transition": "0.4s linear" /* Firefox 4 */,
    "-webkit-transition": "0.4s linear" /* Safari and Chrome */,
    "-o-transition": "0.4s linear" /* Opera */,
    "-ms-transition": "0.4s linear",
  },
  profileImg: {
    width: 60,
    height: 60,
    margin: "0 20px",
    borderRadius: "50%",
    boxShadow: "0 2px 4px 0px rgba(115, 166, 227, 0.4)",
    border: "3px solid #ffffff",
  },
  price: {
    width: 60,
    height: 60,
    margin: "0 20px",
    backgroundColor: "#d50951",
    borderRadius: "50%",
    boxShadow: "0 2px 4px rgba(0, 97, 161, 0.86)",
    border: "3px solid #ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 18,
    fontWeight: "bold",
    color: "white",
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    color: "white",
  },
  btnGp: {
    color: "white",
    height: 30,
    fontSize: 13,
    fontWeight: 600,
    textTransform: "capitalize",
  },
  active: {
    borderBottom: "5px solid #d50951",
  },
}));

const Mobilesizenav = () => {
  const classes = useStyles();
  useEffect(() => {
    window.addEventListener("scroll", mobileHeader);
    return function clean() {
      window.removeEventListener("scroll", mobileHeader);
    };
  }, []);
  const mobileHeader = () => {
    if (window.pageYOffset < 150) {
      document.getElementsByClassName(classes.mobilenavprofile)[0].style.top =
        "-120px";
    } else {
      document.getElementsByClassName(classes.mobilenavprofile)[0].style.top =
        "0";
    }
  };
  const Detail = useSelector((state) => state.Detail);
  const currency = useSelector((state) => state.currency);
  return (
    <div className={classes.mobilenavprofile}>
      {!Detail.data.data ? null : (
        <div className="flex">
          <img
            className={classes.profileImg}
            src={`http://superadmin.nandwsouk.com/admin/${Detail.data.data.masterImage}`}
          />
          <div className="flex-1">
            <h4 className={classes.title}>{Detail.data.data.masterName}</h4>
            <Rating
              precision={0.1}
              value={Detail.data.data.masterRate}
              readOnly
            />
          </div>
          {Detail.data.data.PricePerHours === 0 ? (
            <div className={classes.price}>Free</div>
          ) : (
            <div className={classes.price}>
              {currency.current.symbol}
              {Detail.data.data.PricePerHours * currency.current.value}
            </div>
          )}
        </div>
      )}
      <div className="flex justify-center">
        <ScrollspyNav
          scrollTargetIds={[
            "Bio",
            "Reviews",
            "Availability",
            "Subjects",
            "Qualifications",
          ]}
          offset={-300}
          activeNavClass={classes.active}
          scrollDuration="200"
          headerBackground="true"
        >
          <ul>
            <a href="#Bio" className="mainav__item">
              <Button className={`font-bold p-2 ${classes.btnGp}`}>
                <FormattedMessage id="app.bio" defaultMessage="bio" />
              </Button>
            </a>
            <a href="#Reviews" className="mainav__item">
              <Button className={`font-bold p-2 ${classes.btnGp}`}>
                <FormattedMessage id="app.reviews" defaultMessage="reviews" />
              </Button>
            </a>
            <a href="#Availability" className="mainav__item">
              <Button className={`font-bold p-2 ${classes.btnGp}`}>
                <FormattedMessage
                  id="app.Availability"
                  defaultMessage="Availability"
                />
              </Button>
            </a>
            <a href="#Subjects" className="mainav__item">
              <Button className={`font-bold p-2 ${classes.btnGp}`}>
                <FormattedMessage id="app.subjects" defaultMessage="subjects" />
              </Button>
            </a>
            <a href="#Qualifications" className="mainav__item">
              <Button className={`font-bold p-2 ${classes.btnGp}`}>
                <FormattedMessage
                  id="app.qualifications"
                  defaultMessage="qualifications"
                />
              </Button>
            </a>
          </ul>
        </ScrollspyNav>
      </div>
    </div>
  );
};

export default Mobilesizenav;
