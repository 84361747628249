import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import { Button } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import ScrollspyNav from "react-scrollspy-nav";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  name: {
    fontSize: 45,
    fontWeight: "bold",
    transition: "0.4s",
  },
  star: {
    fontSize: 30,
    color: "#f8be15",
    transition: "0.4s",
  },
  slogan: {
    marginTop: "0.2em",
    fontSize: 17,
    lineHeight: 1.5,
    fontWeight: 500,
    transition: "0.4s",
    lineClamp: 2,
    boxOrient: "vertical",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  locate: {
    fontSize: 20,
    fontWeight: 600,
    display: "flex",
    flex: 1,
    alignItems: "center",
    transition: "0.4s",
  },
  locateicon: {
    width: 17,
    color: "#2373ff",
    marginRight: 12,
  },
  btnGp: {
    color: "white",
    height: 30,
    fontSize: 18,
    fontWeight: 600,
    textTransform: "capitalize",
  },
  active: {
    borderBottom: "5px solid #d50951",
  },
  right: {
    position: "relative",
    display: "flex",
    height: 245,
    flexDirection: "column",
    [theme.breakpoints.down(1200)]: {
      display: "none",
    },
  },
  spyscroll: {
    marginTop: 0,
  },
}));
const Profile = () => {
  const classes = useStyles();
  const Detail = useSelector((state) => state.Detail);
  useEffect(() => {
    window.addEventListener("scroll", headerHeight);
    return function clean() {
      window.removeEventListener("scroll", headerHeight);
    };
  }, []);
  const headerHeight = () => {
    if (
      !document.getElementsByClassName(classes.name)[0] |
      !document.getElementsByClassName(classes.star)[0] |
      !document.getElementsByClassName(classes.slogan)[0] |
      !document.getElementsByClassName(classes.spyscroll)[0] |
      !document.getElementsByClassName(classes.locate)[0]
    ) {
      return 0;
    }
    if (window.pageYOffset < 50) {
      document.getElementsByClassName(classes.name)[0].style.fontSize = "45px";
      document.getElementsByClassName(classes.star)[0].style.fontSize = "30px";
      document.getElementsByClassName(classes.slogan)[0].style.display =
        "-webkit-box";
      document.getElementsByClassName(classes.locate)[0].style.display = "flex";
    } else {
      document.getElementsByClassName(classes.name)[0].style.fontSize = "35px";
      document.getElementsByClassName(classes.star)[0].style.fontSize = "25px";
      document.getElementsByClassName(classes.slogan)[0].style.display = "none";
      document.getElementsByClassName(classes.locate)[0].style.display = "none";
      document.getElementsByClassName(classes.spyscroll)[0].style.marginTop =
        "50px";
    }
  };
  return (
    <div className={`w-4/6 ${classes.right}`}>
      {!Detail.data.data ? null : (
        <>
          <h1 className={classes.name}>{Detail.data.data  ? Detail.data.data.masterName:"-"}</h1>
          <span>
            <Rating
              precision={0.1}
              value={Detail.data.data  ? Detail.data.data.masterRate:'-'}
              className={classes.star}
              readOnly
            />
          </span>
          <h4 className={classes.slogan}>
            {Detail.data.data  ? Detail.data.data.masterDescription:'-.101-'}
          </h4>
          <p className={classes.locate}>
            <svg
              data-v-bdc48200=""
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="map-marker-alt"
              role="img"
              xmlns="https://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              className={classes.locateicon}
            >
              <path
                data-v-bdc48200=""
                fill="currentColor"
                d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
              ></path>
            </svg>
            {Detail.data.data 
              ? Detail.data.data.Citytitle
              : "Every Where .."}
          </p>
        </>
      )}
      <div className={classes.spyscroll}>
        <ScrollspyNav
          scrollTargetIds={[
            "Bio",
            "Reviews",
            "Availability",
            "Subjects",
            "Qualifications",
          ]}
          offset={-360}
          activeNavClass={classes.active}
          scrollDuration="200"
          headerBackground="true"
        >
          <ul>
            <a href="#Bio" className="mainav__item">
              <Button className={`font-bold py-2 px-4 ${classes.btnGp}`}>
                <FormattedMessage id="app.bio" defaultMessage="bio" />
              </Button>
            </a>
            <a href="#Reviews" className="mainav__item">
              <Button className={`font-bold py-2 px-4 ${classes.btnGp}`}>
                <FormattedMessage id="app.reviews" defaultMessage="reviews" />
              </Button>
            </a>
            <a href="#Availability" className="mainav__item">
              <Button className={`font-bold py-2 px-4 ${classes.btnGp}`}>
                <FormattedMessage
                  id="app.Availability"
                  defaultMessage="Availability"
                />
              </Button>
            </a>
            <a href="#Subjects" className="mainav__item">
              <Button className={`font-bold py-2 px-4 ${classes.btnGp}`}>
                <FormattedMessage id="app.subjects" defaultMessage="subjects" />
              </Button>
            </a>
            <a href="#Qualifications" className="mainav__item">
              <Button className={`font-bold py-2 px-4 ${classes.btnGp}`}>
                <FormattedMessage
                  id="app.qualifications"
                  defaultMessage="qualifications"
                />
              </Button>
            </a>
          </ul>
        </ScrollspyNav>
      </div>
    </div>
  );
};

export default Profile;
