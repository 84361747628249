import React from "react";
import Title from "components/landing/Title";
import { makeStyles } from "@material-ui/core/styles";
import Countes from "components/landing/Countes";
const useStyles = makeStyles((theme) => ({
  root: {
    background: "#f2f9ff",
    position: "relative",
    "&:before": {
      position: "absolute",
      top: -250,
      width: "100%",
      height: 250,
      content: "''",
      backgroundImage:
        "url(https://interneturok.ru//static/img/section_bg-c.svg)",
    },
  },
  wrapper: {
    maxWidth: 1150,
    margin: "auto",
    textAlign: "center",
  },
}));
const Leaders = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Title black="header.leaders" blue="header.fieldof" />
        <Countes white={true} />
        <h3 className="text-xl font-medium mt-16 leading-10">
          Our mission:
          <br /> Give every student the freedom to learn from the best teachers
        </h3>
      </div>
    </div>
  );
};

export default Leaders;
