import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    padding: "30px 15px",
    zIndex: 3,
    height: 210,
    backgroundColor: "white",
    borderRadius: 10,
    boxShadow: "0 1px 2px rgba(0, 0, 0, .14)",
    cursor: "pointer",
    margin: "10px",
  },
  cardicon: {
    width: 100,
    height: 100,
  },
}));

const OpportunitiesCard = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.card}>
      <div className="flex justify-center">
        <img alt="icon" className={classes.cardicon} src={props.image} />
      </div>
      <div className="flex justify-center">
        <p className="text-center my-3">{props.text}</p>
      </div>
    </div>
  );
};

export default OpportunitiesCard;
