import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Arshive from "./Arshive";
import Card from "./Card";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1100,
    margin: "auto",
    [theme.breakpoints.down(900)]: {
      width: "auto",
      padding: 20,
    },
  },
  wrapper: {
    display: "flex",
    [theme.breakpoints.down(900)]: {
      display: "block",
    },
  },
  card: {
    [theme.breakpoints.down(900)]: {
      width: "100% !important",
      margin: "auto !important",
    },
  },
  paginnation: {
    maxWidth: 350,
    margin: "40px auto",
  },
  error: {
    color: "#d50951",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 35,
    marginTop: 100,
  },
}));
const Cards = () => {
  const classes = useStyles();
  const bestTutors = useSelector((state) => state.bestTutors);
  return (
    <div>
      <div className={classes.root}>
        <div className={`mb-4 ${classes.wrapper}`}>
          <div className={`w-3/4 mx-2 ${classes.card}`}>
            {bestTutors?.data?.cnt === 0 && (
              <p className={classes.error}>There is no data</p>
            )}
            {bestTutors.loading === true ? (
              <div
                style={{ height: 250 }}
                className="flex justify-center items-center"
              >
                <CircularProgress className="relative" size={100} />
              </div>
            ) : (
              <React.Fragment>
                {!bestTutors.data.data ? null : (
                  <>
                    {bestTutors.data.data.map((item, i) => {
                      return (
                        <div key={i}>
                          <Card
                            img={item.images}
                            name={item.name}
                            text={item.description}
                            star={item.masterRate+1}
                            price={item.like}
                            product={item.categoriesObject.length>0 ? item.categoriesObject:""}
                            slogan={item.slug}
                            repeat={item.categoriesObject.length>0 ? item.categoriesObject[0].categoryLabelSlug:""}
                            time={item.categoriesObject.length>0 ? item.categoriesObject[0].categoryLabelSlug:""}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
              </React.Fragment>
            )}
          </div>
          <Arshive />
        </div>
      </div>
    </div>
  );
};

export default Cards;
