import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 200,
    position: "relative",
    textAlign: "center",
    color: "white",
  },
  txt: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: 20,
    fontWeight: 600,
    [theme.breakpoints.down(600)]: {
      fontSize: 15,
    },
  },
}));

const CustomersState = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img alt={props.stateTxt} src={props.stateImg} />
      <p className={classes.txt}>{props.stateTxt}</p>
    </div>
  );
};

export default CustomersState;
