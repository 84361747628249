import { STAR } from "./star.action";

const initialState = {
  star: [0,5],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STAR:
      return {
        ...state,
        star: action.star,
      };
    default:
      return state;
  }
};
export default reducer;
