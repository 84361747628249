import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      "url(https://tutor.id/static/images/img-student-cta.jpg) repeat scroll center center / cover",
    position: "relative",
    width: "100%",
    minHeight: 450,
  },
  wrapper: {
    maxWidth: 1150,
    margin: "auto",
    position: "relative",
    padding: "100px 0",
    color: "white",
    [theme.breakpoints.down(1200)]: {
      margin: 20,
      textAlign: "center",
    },
  },
  headtitle: {
    fontSize: 30,
    fontWeight: 500,
    lineHeight: "2em",
  },
  subTitle: {
    fontSize: 18,
    marginBottom: 45,
  },
  lists: {
    position: "relative",
    [theme.breakpoints.down(1200)]: {
      maxWidth: 300,
      margin: "auto",
    },
  },
  listItem: {
    fontSize: 18,
    paddingLeft: 45,
    marginBottom: "25px !important",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    "&:before": {
      content: "''",
      backgroundSize: "contain",
      backgroundColor: "white",
      borderRadius: "50%",
      padding: 5,
      backgroundRepeat: "no-repeat",
      width: 32,
      height: 32,
      position: "absolute",
      left: 0,
      display: "block",
    },
    "&:after": {
      content: "''",
      background: "url(https://tutor.id/static/svg/icon-check.svg) no-repeat",
      width: 32,
      height: 32,
      position: "absolute",
      left: 0,
      display: "block",
      backgroundPosition: "center",
      backgroundSize: "18px 14px",
    },
    [theme.breakpoints.down(1200)]: {
      margin: "auto",
      "&:before": {
        content: "''",
        backgroundSize: "contain",
        backgroundColor: "white",
        borderRadius: "50%",
        padding: 5,
        backgroundRepeat: "no-repeat",
        width: 32,
        height: 32,
        position: "absolute",
        display: "block",
      },
      "&:after": {
        content: "''",
        background: "url(https://tutor.id/static/svg/icon-check.svg) no-repeat",
        width: 32,
        height: 32,
        position: "absolute",
        display: "block",
        backgroundPosition: "center",
        backgroundSize: "18px 14px",
      },
    },
  },
  getstartbtn: {
    display: "flex",
    backgroundColor: "rgb(0, 204, 136)",
    color: "white",
    height: 50,
    padding: "15px 28px 15px",
    fontSize: 15,
    borderRadius: 30,
    "&:hover": {
      backgroundColor: "rgb(2, 163, 120)",
    },
    [theme.breakpoints.down(1200)]: {
      margin: "auto",
    },
  },
}));
const GetStart = ({ intl }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.title}>
          <h2 className={classes.headtitle}>
            <FormattedMessage
              id="Tutors.app.getStart.title"
              defaultMessage="What are you waiting for?"
            />
          </h2>
          <p className={classes.subTitle}>
            <FormattedMessage id="Tutors.app.getStart.subtitle" />
          </p>
        </div>
        <ul className={classes.lists}>
          {intl.messages.GetStartList.map((item, i) => (
            <li className={classes.listItem} key={i}>
              {item.title}
            </li>
          ))}
        </ul>
        <Button className={classes.getstartbtn}>
          <FormattedMessage
            id="Tutors.app.getStart.Btn"
            defaultMessage="Get Started"
          />
        </Button>
      </div>
    </div>
  );
};

export default injectIntl(GetStart);
