import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button, InputBase, NativeSelect, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getCategoty } from "store/searchCategory";
import { getSubjectSuccess } from "store/subject";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    height: 320,
    backgroundColor: "#232f49",
    color: "white",
    "--skew-path-sm": "0px 0, 100% 0px, 100% 80%, 0% 100%",
    clipPath: "polygon(var(--skew-path-sm))",
    [theme.breakpoints.down(1200)]: {
      height: "auto",
    },
  },
  wrapper: {
    maxWidth: 1100,
    margin: "auto",
    padding: "80px 0",
    [theme.breakpoints.down(1250)]: {
      padding: "80px 20px",
    },
  },
  headTitle: {
    fontSize: "2rem",
    fontWeight: 700,
    [theme.breakpoints.down(750)]: {
      fontSize: "1.8rem",
    },
  },
  subTitle: {
    fontSize: "1.2rem",
    fontWeight: "lighter",
    lineHeight: 1.5,
    [theme.breakpoints.down(750)]: {
      fontSize: "0.9rem",
      marginTop: 50,
    },
  },
  title: {
    width: "100%",
    [theme.breakpoints.down(1200)]: {
      margin: "auto",
      textAlign: "center",
      width: "auto",
    },
  },
  autoComplete: {
    width: 380,
    borderRadius: 0,
    height:50,
    padding: "0 15px",
    color: "black",
    backgroundColor: "white",
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    [theme.breakpoints.down(650)]: {
      maxWidth: 220,
    },
  },
  autoCompletefield: {
    backgroundColor: "white",
    width: 250,
    color: "black",
    height: 50,
    borderRadius: 6,
  },
  searchBtn: {
    backgroundColor: "#db2b21",
    color: "white",
    width: 120,
    margin: "0 20px",
    "&:hover": {
      backgroundColor: "#277727",
    },
    [theme.breakpoints.down(650)]: {
      margin: 0,
    },
  },
  fields: {
    display: "flex",
    [theme.breakpoints.down(1200)]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down(650)]: {
      display: "block",
      maxWidth: 300,
      margin: "auto",
    },
  },
  field2: {
    [theme.breakpoints.down(650)]: {
      width: "100%",
    },
  },
  "@global": {
    ".MuiNativeSelect-icon": {
      right: 12,
    },
    ".MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot, .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
      padding:"6px !important"
    },
  },
}));
const Input2 = withStyles((theme) => ({
  input: {
    padding: 17,
    width: 120,
    height:14,
    paddingRight: 20,
    color: "black",
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    borderRadius: 0,
    backgroundColor: "#db2b21",
    border: "1px solid #db2b21",
    fontSize: 16,
    "&:focus": {
      borderColor: "#db2b21",
      backgroundColor: "#db2b21",
      boxShadow: "0 0 0 0.1rem rgba(0,123,255,.25)",
    },
    [theme.breakpoints.down(350)]: {
      width: "auto",
    },
  },
}))(InputBase);

const HeadBanner = (props) => {
  const match = useRouteMatch({ path: "/:language/:city?" });
  const { language, city = "all-country" } = match.params;
  const classes = useStyles();
  // Redux
  const [selectvalue, setselectvalue] = useState("");
  const dispatch = useDispatch();
  const Category = useSelector((state) => state.searchC);
  const Subject = useSelector((state) => state.SearchSubject);
  const SearchVal = useSelector((state) => state.Searchvalue);

  // Effect
  useEffect(() => {
    dispatch(getCategoty());
    dispatch(getSubjectSuccess());
  }, []);

  const handleChange = (event) => {
    setselectvalue(event.target.value);
  };
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.title}>
          <h2 className={classes.headTitle}>
            <FormattedMessage
              id="componies.app.headTitle"
              defaultMessage="Найти поставщика"
            />
          </h2>
          <p className={classes.subTitle}>
            <FormattedMessage
              id="componies.app.subTitle"
              defaultMessage="Connect with talented"
            />
          </p>
          <div className={classes.fields}>
            <div className="flex py-4">
              <input
                className={classes.autoComplete}
                type="text"
                onChange={props.searchByName}
                value={SearchVal.data}
                placeholder={props.intl.formatMessage({
                  id: "home.hero.FindByName",
                  defaultMessage: "FindByName",
                })}
              />
              <NativeSelect
                id="demo-customized-select-native"
                value={selectvalue}
                onChange={handleChange}
                input={<Input2 />}
              >
                <FormattedMessage id="app.default">
                  {(id) => (
                    <option onClick={props.subjectOnClick} value="">
                      {id}
                    </option>
                  )}
                </FormattedMessage>
                {!Subject.data.data
                  ? null
                  : Subject.data.data.map((item, i) => (
                      <option
                        key={i}
                        value={item.title}
                        onClick={props.subjectOnClick}
                      >
                        {language === "fa" ? item.translate.fa : ""}
                        {language === "ar" ? item.translate.ar : ""}
                        {language === "en" ? item.translate.en : ""}
                        {language === "ru" ? item.translate.ru : ""}
                      </option>
                    ))}
              </NativeSelect>
            </div>
            <div className={`flex sm:p-4 sm:justify-center`}>
              <Autocomplete
                className={classes.autoCompletefield}
                onChange={props.categoryOnClick}
                options={!Category.data.data ? [] : Category.data.data}
                getOptionLabel={(option) => option.translate.fa}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={props.intl.formatMessage({
                      id: "app.BrowseCategory",
                      defaultMessage: "Browse By Category",
                    })}
                    variant="outlined"
                  />
                )}
              />

              <Button className={classes.searchBtn}>
                <SearchIcon />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default injectIntl(HeadBanner);
