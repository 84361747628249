import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDetailAction } from "store/tutorsDetail";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    border: "1px solid #e2e2e2",
    boxShadow: "0 2px 4px rgba(74,144,226,.25) !important",
    minHeight: 300,
    color: "#232f49 !important",
  },
  img: {
    width: 100,
    borderRadius: "50%",
  },
  profile: {
    borderBottom: "1px solid #e2e2e2",
  },
}));

const Card = () => {
  let slug = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDetailAction(slug.language, slug.id));
  }, []);
  const Detail = useSelector((state) => state.Detail);
  const currency = useSelector((state) => state.currency);
  const ReqLesson = useSelector((state) => state.ReqLesson);
  const classes = useStyles();
  return (
    <div className={`w-full py-4 px-6 ${classes.root}`}>
      {!Detail.data.data ? null : (
        <div className={`flex pb-6 ${classes.profile}`}>
          <img
            src={Detail.data.data.masterImage}
            className={classes.img}
          />
          <div className="mx-4">
            <p className="text-xl font-black ">
              {Detail.data.data.masterName}
            </p>
            <p className="text-3xl font-black ">
              {currency.current.symbol}
              {Detail.data.data.PricePerHours * currency.current.value}
              <span className="text-sm font-normal px-1">/ hr</span>
            </p>
            <Rating
              readOnly
              precision={0.1}
              value={Detail.data.data.masterRate}
            />
          </div>
        </div>
      )}
      <div className="pt-6">
        <h4 className="font-black text-lg">About your lesson</h4>
        <div className="flex items-center justify-between mt-4 text-sm ">
          <span>Subject / Level</span>
          <p className="font-black">
            {ReqLesson.subject}({ReqLesson.level})
          </p>
        </div>
        <div className="flex items-center justify-between mt-4 text-sm ">
          <span>Location</span>
          <p className="font-black">{ReqLesson.place}</p>
        </div>
        <div className="flex items-center justify-between mt-4 text-sm ">
          <span>Frequency</span>
          <p className="font-black">{ReqLesson.often}</p>
        </div>
        <div className="flex items-center justify-between mt-4 text-sm ">
          <span>Date</span>
          <p className="font-black">{ReqLesson.date}</p>
        </div>
        <div className="flex items-center justify-between mt-4 text-sm ">
          <span>Start time</span>
          <p className="font-black">Invalid Date</p>
        </div>
        <div className="flex items-center justify-between mt-4 text-sm ">
          <span>Duration</span>
          <p className="font-black">{ReqLesson.duration}</p>
        </div>
        <div className="flex items-center justify-between mt-4 text-sm ">
          <span>For</span>
          <p className="font-black">{ReqLesson.lessonsfor}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
