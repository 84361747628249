import {
  SEARCH_VALUE,
  SUBJECT_VALUE,
  CATEGORY_VALUE,
  PRICE_VALUE,
  RATE_VALUE,
  PAGE_VALUE,
} from "./search.action";

const initialState = {
  value: "",
  subjectvalue: "",
  categoryvalue: "",
  pricevalue: [0, 5000000],
  ratevalue: [0, 5],
  pagevalue: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_VALUE:
      return {
        ...state,
        value: action.value,
      };
    case SUBJECT_VALUE:
      return {
        ...state,
        subjectvalue: action.subjectvalue,
      };
    case CATEGORY_VALUE:
      return {
        ...state,
        categoryvalue: action.categoryvalue,
      };
    case PRICE_VALUE:
      return {
        ...state,
        pricevalue: action.pricevalue,
      };
    case RATE_VALUE:
      return {
        ...state,
        ratevalue: action.ratevalue,
      };
    case PAGE_VALUE:
      return {
        ...state,
        pagevalue: action.pagevalue,
      };
    default:
      return state;
  }
};
export default reducer;
