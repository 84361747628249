import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    backgroundImage: "url(https://interneturok.ru//static/img/girrl-bg.jpg)",
    width: "100%",
    height: 500,
    overflow: "hidden",
    padding: "150px 0",
    [theme.breakpoints.down(700)]: {
      backgroundPosition:"15%"
     },
  },
  wrapper: {
    maxWidth: 1000,
    margin: "auto",
    display: "flex",
    [theme.breakpoints.down(1200)]: {
      flexDirection:"column",
      alignItems:"center",
      textAlign:"center"
     },
  },
  title: {
    fontSize: 30,
    fontWeight: 700,
    marginBottom: 30,
    [theme.breakpoints.down(1000)]: {
      fontSize:25,
     },
  },
  showmore: {
    backgroundColor: "#FE9023",
    color: "white",
    padding: "12px 0",
    transition: "0.3s",
    height: 55,
    padding: "20px 50px",
    borderRadius: 70,
    fontSize:12,
    "&:hover": {
      backgroundColor: "#FE9023",
      transition: "0.3s",
      padding: "35px 60px",
    },
    [theme.breakpoints.down(1000)]: {
     },
  },
}));
const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className="w-1/2" />
        <div className="md:w-1/2 w-full">
          <h3 className={classes.title}>
            General education <br />
            <span style={{ color: "#FE9023" }}>online school</span>
          </h3>
          <p className="text-lg  text-gray-700 mb-10">
            For those who want to learn effectively. Complete secondary
            education from 1 to 11 grades remotely.
          </p>
          <Button className={classes.showmore}>More details</Button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
