import React from "react";
import HeadBanner from "components/curriculum/HeadBanner";
import Tutorials from "components/curriculum/Tutorials";
import ScrollTop from "components/landing/ScrollTop";
import VideoTutorials from "components/curriculum/VideoTutorials";
import Footer from "components/abonement/Footer";
import Questions from "components/landing/Questions";
import InternetLessons from "components/curriculum/InternetLessons";
import Leaders from "components/curriculum/Leaders";
import Missings from "components/curriculum/Missings";
import Tariffs from "components/curriculum/Tariffs";
import SeeBanner from "components/curriculum/SeeBanner";
function Curriculum() {
  return (
    <div>
      <ScrollTop />
      <HeadBanner />
      <Tutorials />
      <VideoTutorials />
      <InternetLessons />
      <Leaders/>
      <Missings/>
      <Tariffs/>
      <SeeBanner/>
      <Questions />
      <Footer />
    </div>
  );
}

export default Curriculum;
