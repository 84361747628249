import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  IconButton,
  TextField,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "store/dialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 35,
    fontWeight: 700,
    transition: "0.4s",
    marginBottom: "0.5em",
    margin: "0 5px",
  },
  subform: {
    color: "gray",
    margin: "0 5px",
    marginBottom: "1.5em",
  },
  submit: {
    color: "white",
    width: "100%",
    backgroundColor: "#ffe033",
    padding: 20,
    marginTop: "0.5em",
    "&:hover": {
      backgroundColor: "#ffd033",
    },
  },
  closeButton: {
    position: "absolute",
    width: 50,
    height: 50,
    fontSize: 50,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "gray",
  },
}));
const ContactForm = (props) => {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const Detail = useSelector((state) => state.Detail);
  const onSubmit = (data) => console.log(data);
  const [checked, setChecked] = React.useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8"
      >
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={props.closeDialog}
        >
          <Icon style={{ fontSize: 35 }}>close</Icon>
        </IconButton>
        <h2 className={classes.title}>My Information</h2>
        <div className="flex items-center justify-between mb-4">
          <div className="w-full mx-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Shop
            </label>
            <Autocomplete
              options={!Detail.data ? [] : Detail.data.data.categoriesObject}
              getOptionLabel={(option) => option.slug}
              renderInput={(params) => (
                <TextField
                  name="Shop_1"
                  inputRef={register}
                  {...params}
                  label="Enter Shopping Address"
                  variant="outlined"
                />
              )}
            />
          </div>
          <div className="w-full mx-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Shop
            </label>
            <Autocomplete
              options={!Detail.data ? [] : Detail.data.data.Qualifications}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  name="Shop_2"
                  inputRef={register}
                  {...params}
                  label="Enter Shopping Address"
                  variant="outlined"
                />
              )}
            />
          </div>
        </div>
        <div style={{ width: "98.4%" }} className="mx-2 mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Question
          </label>
          <textarea
            name="Question"
            ref={register}
            placeholder="Hi  would like to be tutored at my house. if you are able to help and your availability.Thanks."
            className="mb-4 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="flex items-center justify-between mb-4">
          <div className="w-full mx-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Your Name
            </label>
            <input
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="name"
              ref={register}
              placeholder="Your Name"
            />
          </div>
          <div className="w-full mx-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Email
            </label>
            <input
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="email"
              name="email"
              ref={register}
              placeholder="Email"
            />
          </div>
        </div>
        <div className="flex items-center justify-between mb-4">
          <div className="w-full mx-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Phone
            </label>
            <input
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="Phone"
              ref={register}
              placeholder="Phone"
            />
          </div>
          <div className="w-full mx-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Post Code
            </label>
            <input
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="PostCode"
              ref={register}
              placeholder="Post Code"
            />
          </div>
        </div>
        <div className="flex items-center justify-between mb-4">
          <div className="w-full mx-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Password
            </label>
            <input
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="password"
              name="Password"
              ref={register}
              placeholder="Password"
            />
          </div>
          <div className="w-full mx-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Confrim Password
            </label>
            <input
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="password"
              name="ConfPassword"
              ref={register}
              placeholder="Confrim Password"
            />
          </div>
        </div>
        <p className={classes.subform}>
          Are there any heath and safety risks at your property? ie. building
          work that we need to inform the tutor about.
        </p>
        <div>
          <FormControlLabel
            style={{ margin: "0 -8px" }}
            control={<Checkbox checked={checked} onChange={handleChange} />}
            label="I agree to this website"
          />
        </div>
        <Button
          disabled={checked ? false : true}
          type="submit"
          className={classes.submit}
        >
          Save Changes
        </Button>
      </form>
    </div>
  );
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeDialog: Actions.closeDialog,
    },
    dispatch
  );
}
export default connect(null, mapDispatchToProps)(ContactForm);
