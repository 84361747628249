import React, { useEffect, useState } from "react";
import Cards from "components/Suppliers/Cards";
import HeadBanner from "components/Suppliers/HeadBanner";
import Mobilesizenav from "components/Suppliers/Mobilesizenav";
import ProfileCard from "components/Suppliers/ProfileCard";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getDetailAction } from "store/tutorsDetail";
import { getCommentsAction } from "store/TutorsComment";
function Suppliers() {
  let slug = useParams();
  const [width, setWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getDetailAction(slug.language, slug.id));
    dispatch(getCommentsAction(slug.language, slug.id));
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return (
    <div style={{ backgroundColor: "#ebeff2" }}>
      {width > 1200 ? (
        <HeadBanner />
      ) : (
        <>
          <Mobilesizenav />
          <ProfileCard />
        </>
      )}
      <div className="p-6">
        <Cards />
      </div>
    </div>
  );
}

export default Suppliers;
