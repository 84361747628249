import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactHtmlParser from "react-html-parser";
// icons
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SmsIcon from "@material-ui/icons/Sms";
import GradeIcon from "@material-ui/icons/Grade";
import DateRangeIcon from "@material-ui/icons/DateRange";
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
// components
import Reviews from "./Reviews";
import Card from "./Card";
import Degree from "./Degree";
import { injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import Availability from "components/blogPost/content/Availability";
import Photos from "./Photos";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#4cbbc2",
    fontSize: 40,
  },
  star: {
    fontSize: 30,
    color: "#f8be15",
    [theme.breakpoints.down(675)]: {
      fontSize: 14,
    },
  },
  cards: {
    marginTop: 240,
    [theme.breakpoints.down(1200)]: {
      marginTop: 0,
    },
  },
}));
const Cards = ({ intl }) => {
  const Detail = useSelector((state) => state.Detail);
  const classes = useStyles();
   
  const availability = !Detail.data.data
    ? "null"
    : Detail.data.data.Availability;
  return (
    <div className={`pb-5 ${classes.cards}`}>
      <Card
        link="Bio"
        title={intl.formatMessage({
          id: "app.bio",
          defaultMessage: "bio",
        })}
        icon={<AccountCircleIcon className={classes.icon} />}
        body={
          !Detail.data.data
            ? null
            : ReactHtmlParser(Detail.data.data.aboutme)
        }
      />
      <Card
        link="Reviews"
        title={intl.formatMessage({
          id: "app.reviews",
          defaultMessage: "reviews",
        })}
        icon={<SmsIcon className={classes.icon} />}
        star={
          <span>
            <GradeIcon className={classes.star} />
            <GradeIcon className={classes.star} />
            <GradeIcon className={classes.star} />
            <GradeIcon className={classes.star} />
            <GradeIcon className={classes.star} />
          </span>
        }
        body={<Reviews />}
      />
      <Card
        link="Availability"
        title={intl.formatMessage({
          id: "app.Availability",
          defaultMessage: "Availability",
        })}
        icon={<DateRangeIcon className={classes.icon} />}
        subtitle="Here is my general availability. "
        body={<Availability list={availability} />}
      />
      <Card
        link="Subjects"
        title={intl.formatMessage({
          id: "profile.photos.MorePhotos",
          defaultMessage: "Photos",
        })}
        icon={<PhotoLibraryIcon className={classes.icon} />}
        body={<Photos/>}
      />
      <Card
        link="Qualifications"
        title={intl.formatMessage({
          id: "app.qualifications",
          defaultMessage: "qualifications",
        })}
        icon={<WorkOutlineIcon className={classes.icon} />}
        body={<Degree />}
      />
    </div>
  );
};

export default injectIntl(Cards);
