import React from "react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1000,
    margin: "auto",
    padding: "4em 0",
  },
  card: {
    width: "100%",
    padding: "25px",
    minHeight: 200,
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    borderRadius: 12,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.14)",
    [theme.breakpoints.down(1000)]:{
      flexDirection:"column",
      textAlign:"center",
    }
  },
  title: {
    fontSize: 25,
    fontWeight: 600,
    marginBottom: 20,
    [theme.breakpoints.down(1000)]:{
      marginTop:20
    }
  },
}));
const Info = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <img
          width={100}
          className="mx-12"
          src="https://interneturok.ru//static/img/card-info.jpg"
          alt="info"
        />
        <div className="w-7/12">
          <h3 className={classes.title}>Attention teacher!</h3>
          <p className="text-sm" >
            To get a subscription at any time for free, send a scan of the
            certificate from the place of work to email:{" "}
            <span style={{ color: "#1062D8", fontWeight: 700 }}>
              info@interneturok.ru
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Info;
