export const STAR = "STAR";

const star = (star) => ({
  type: STAR,
  star: star,
});
export const StarAction = (newstate) => {
  return (dispatch) => {
    dispatch(star(newstate));
  };
};
