import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";
import ReasonsCard from "./ReasonsCard";
import CustomersState from "./StudentsState";
import Title from "./Title";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1150,
    margin: "auto",
    padding: "5em 0",
  },
  cardReason: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down(1000)]: {
      display: "block",
    },
  },
}));
const StudentReasons = ({ intl }) => {
  const classes = useStyles();
  return (
    <div className="bg-white">
      <div className={classes.root}>
        <Title title={intl.formatMessage({
                  id: "Tutors.app.students.title",
                  defaultMessage: "Why Tutor.id",
                })} />
        <div className={classes.cardReason}>
          {intl.messages.Customers.map((item, i) => (
            <div key={i}>
              <ReasonsCard
                image={item.image}
                title={item.title}
                text={item.text}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-8">
          <CustomersState
            stateImg="https://tutor.id/static/svg/blob-left.svg"
            stateTxt={intl.formatMessage({
              id: "Tutors.app.students.studentsState1",
              defaultMessage: "16381 Bookings",
            })}
          />
          <CustomersState
            stateImg="https://tutor.id/static/svg/blob-middle.svg"
            stateTxt={intl.formatMessage({
              id: "Tutors.app.students.studentsState2",
              defaultMessage: "24742 Hours of lessons",
            })}
          />
          <CustomersState
            stateImg="https://tutor.id/static/svg/blob-right.svg"
            stateTxt={intl.formatMessage({
              id: "Tutors.app.students.studentsState3",
              defaultMessage: "98% User satisfaction",
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(StudentReasons);
