import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { WeekAction } from "store/searchBySubjects";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1200,
    margin: "50px auto",
  },
  title: {
    color: "#232f49",
    fontSize: "1.3rem",
    fontWeight: 700,
    [theme.breakpoints.down(900)]: {
      textAlign: "center",
    },
  },
  subtitle: {
    color: "#545454",
    fontSize: 15,
  },
  skip: {
    backgroundColor: "#e2e2e2",
    color: "#545454",
    width: 90,
    height: 30,
  },
}));
const TabelWeek = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const skipHandler = () => {
    dispatch(WeekAction(" "));
  };
  return (
    <div data-aos="fade-up" className={classes.root}>
      <div className="justify-between flex ">
        <h2 className={classes.title}>When would you like your lesson?</h2>
        <Button onClick={skipHandler} className={classes.skip}>
          Skip
        </Button>
      </div>
      <p className={classes.subtitle}>
        Choose as many slots as you like and we will match you with tutors who
        can help during those periods.
      </p>

      <div className="flex flex-wrap w-full "></div>
    </div>
  );
};

export default TabelWeek;
