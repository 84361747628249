export const SUBJECT = "SUBJECT";
export const LEVEL = "LEVEL";
export const PLACE = "PLACE";
export const POSTCODE = "POSTCODE";
export const LESSONSFOR = "LESSONSFOR";
export const OFTEN = "OFTEN";
export const DATE = "DATE";
export const DURATION = "DURATION";
export const DISCRIPTION = "DISCRIPTION";

const subject = (subject) => ({
  type: SUBJECT,
  subject: subject,
});
export const subjectReqAction = (newstate) => {
  return (dispatch) => {
    dispatch(subject(newstate));
  };
};
const level = (level) => ({
  type: LEVEL,
  level: level,
});
export const levelReqAction = (newstate) => {
  return (dispatch) => {
    dispatch(level(newstate));
  };
};
const place = (place) => ({
  type: PLACE,
  place: place,
});
export const placeReqAction = (newstate) => {
  return (dispatch) => {
    dispatch(place(newstate));
  };
};
const postcode = (postcode) => ({
  type: POSTCODE,
  postcode: postcode,
});
export const postcodeReqAction = (newstate) => {
  return (dispatch) => {
    dispatch(postcode(newstate));
  };
};
const lessonsfor = (lessonsfor) => ({
  type: LESSONSFOR,
  lessonsfor: lessonsfor,
});
export const lessonsforReqAction = (newstate) => {
  return (dispatch) => {
    dispatch(lessonsfor(newstate));
  };
};
const often = (often) => ({
  type: OFTEN,
  often: often,
});
export const oftenReqAction = (newstate) => {
  return (dispatch) => {
    dispatch(often(newstate));
  };
};
const date = (date) => ({
  type: DATE,
  date: date,
});
export const dateReqAction = (newstate) => {
  return (dispatch) => {
    dispatch(date(newstate));
  };
};
const duration = (duration) => ({
  type: DURATION,
  duration: duration,
});
export const durationReqAction = (newstate) => {
  return (dispatch) => {
    dispatch(duration(newstate));
  };
};
const discription = (discription) => ({
  type: DISCRIPTION,
  discription: discription,
});
export const discriptionReqAction = (newstate) => {
  return (dispatch) => {
    dispatch(discription(newstate));
  };
};
