import React from "react";
import { useSelector } from "react-redux";
import Review from "./Review";

const Reviews = () => {
  const Comments = useSelector((state) => state.Comments);
  return (
    <div>
      {!Comments.data.data
        ? null
        : Comments.data.data.map((item, i) => {
            return (
              <div key={i}>
                <Review
                  name={item.sender}
                  comment={item.text}
                  date={item.date ? item.date : item.target}
                  rate={item.rate}
                />
                <hr />
              </div>
            );
          })}
    </div>
  );
};

export default Reviews;
