import HeadBanner from "components/navitech/HeadBanner";
import Boxes from "components/navitech/Boxes";
import React from "react";
import Main from "components/navitech/Main";
import Photos from "components/navitech/Photos";
import News from "components/navitech/News";
import MediaPartners from "components/navitech/MediaPartners";
import Googlemap from "components/navitech/Googlemap";

function Navitech() {
  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <HeadBanner />
      <Boxes />
      <Main />
      <Photos />
      <News />
      <MediaPartners />
      <Googlemap />
    </div>
  );
}

export default Navitech;
