import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { dateReqAction, durationReqAction } from "store/requestLesson";

const data = [
  { title: "0:30" },
  { title: "0:45" },
  { title: "1:00" },
  { title: "1:30" },
  { title: "1:45" },
  { title: "2:00" },
  { title: "2:30" },
  { title: "2:45" },
  { title: "3:00" },
];
const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiAutocomplete-inputRoot": {
      borderRadius: 0,
    },
    ".MuiAutocomplete-root": {
      width: "150px !important",
    },
    ".MuiInputBase-root": {
      height: "40px !important",
      backgroundColor: "white",
    },
    ".MuiInputBase-input ": {
      marginTop: -8,
      fontSize: 12,
    },
    ".MuiFormLabel-root": {
      marginTop: -6,
    },
  },
  textarea: {
    width: "100%",
    padding: 10,
    lineHeight: 1.2,
    fontSize: 15,
    minHeight: 100,
    fontWeight: 100,
    border: "1px solid #e2e2e2",
    "&::placeholder": {
      color: "black",
      fontStyle: "italic",
    },
    "&:focus": {
      border: "1px solid #db2b21",
      boxShadow: "0 0 0 .2rem rgba(92,184,92,.25)",
    },
  },
  date: {
    width: 250,
    border: "1px solid #e2e2e2",
    padding: 10,
    cursor: "pointer",
  },
}));
const TimeOfLesson = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [date, setdate] = useState(0);
  const handleDuration = (event, value) => {
    dispatch(durationReqAction(value === null ? "1:00" : value.title));
  };
  const handleDateChange = (e) => {
    setdate(e.target.value);
    dispatch(
      dateReqAction(e.target.value === "" ? "Invalid Date" : e.target.value)
    );
  };
  return (
    <div className="mt-6">
      <h6 className="font-bold text-base text-gray-700">
        How often would you like tuition?
      </h6>
      <div className="flex mt-8 justify-between">
        <input
          onChange={handleDateChange}
          value={date}
          className={classes.date}
          type="date"
          placeholder="Date"
        />
        <Autocomplete
          id="Duration"
          onChange={handleDuration}
          options={data}
          getOptionLabel={(option) => option.title}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Duration" variant="outlined" />
          )}
        />
      </div>
      <h6 className="font-bold text-base text-gray-700 my-6">
        Introduce yourself to Sylvana and describe what you're looking for
      </h6>
      <textarea
        minLength={50}
        className={classes.textarea}
        placeholder="Let Sylvana know how they can help, what you want to learn, what you struggle with and what you want to get from tuition. 
 
 Make sure you don’t include any personal information"
      />
    </div>
  );
};

export default TimeOfLesson;
