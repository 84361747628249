import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 30,
    fontWeight: 500,
    lineHeight:1.2,
    marginBottom:30,
    [theme.breakpoints.down(900)]: {
      fontSize: 25,
    },
  },
}));

const Title = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.title}>
      <FormattedMessage id={props.black} />
      <span style={{ color: "#db2b21", padding: "0.2em" }}>
        <FormattedMessage id={props.blue} />
      </span>
    </div>
  );
};

export default Title;
