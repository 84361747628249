import React, { useEffect, useState } from "react";
import HeadBanner from "components/search/HeadBanner";
import Cards from "components/search/Cards";
import SearchFiled from "components/search/SearchFiled";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { getCategoty } from "store/searchCategory";
import Level from "components/search/Level";
import { makeStyles } from "@material-ui/core/styles";
import TabelWeek from "components/search/TabelWeek";
import Where from "components/search/Where";
import { Button } from "@material-ui/core";
import NavLevel from "components/search/NavLevel";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1200,
    margin: "auto",
    paddingBottom: 100,
  },
  dot: {
    width: 15,
    height: 15,
    margin: 20,
    borderRadius: "50%",
  },
  submitBtn: {
    backgroundColor: "#db2b21",
    padding: "15px 40px",
    color: "white",
    "&:hover": {
      backgroundColor: "#5aa85c",
    },
  },
}));

function Search() {
  const classes = useStyles();
  const match = useRouteMatch({ path: "/:language" });
  const dispatch = useDispatch();
  const { language } = match.params;

  useEffect(() => {
    dispatch(getCategoty());
  }, []);
  const SubjectSearch = useSelector((state) => state.SubjectSearch);
  return (
    <div style={{ backgroundColor: "#F6F9FC" }}>
      <HeadBanner />
      <div className={classes.root}>
        <div className="flex">
          <div className="md:w-3/4 w-full">
            <Cards />
            <SearchFiled />
            {!SubjectSearch.subjects ? null : <Level />}
            {!SubjectSearch.level ? null : <Where />}
            {!SubjectSearch.where ? null : <TabelWeek />}
            {!SubjectSearch.week ? null : (
              <a href="/:language/myProfile">
                <Button className={classes.submitBtn}>View Your Tutors</Button>
              </a>

            )}
          </div>
          <NavLevel />
        </div>
      </div>
    </div>
  );
}

export default Search;
