import React, { Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import Tutors from "pages/Tutors";
import Suppliers from "pages/Suppliers";
import Search from "pages/Search";
import Companies from "pages/Companies";
import Abonement from "pages/Abonement";
import Curriculum from "pages/Curriculum";
import Navitech from "pages/Navitech";
import AskQuestion from "pages/AskQuestion";
const Landing = React.lazy(() => import("./pages/Landing"));

const Pay = React.lazy(() => import("./components/pay/Pay"));
const BlogPost = React.lazy(() => import("./components/blogPost/BlogPost"));
const Checkout = React.lazy(() => import("./components/checkout/Checkout"));
const Tutorcategory = React.lazy(() =>
  import("./components/tutorcategory/Tutorcategory")
);
const Blog = React.lazy(() => import("./components/blog/Blog"));
const useStyles = makeStyles(() => ({
  defaultLayout: {
    width: "100%",
  },
}));

const Layout = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  });
  const classes = useStyles();
  const { path } = useRouteMatch();

  return (
    <Fragment>
      <div className={classes.defaultLayout}>
        <Switch>
          <Route
            exact
            path={`${path}/`}
            render={(props) => <Landing {...props} />}
          />
          <Route path={`${path}/Tutors/`} component={Tutors} />
          <Route path={`${path}/Suppliers/:id/`} exact component={Suppliers} />
          <Route path={`${path}/Search/`} component={Search} />
          <Route path={`${path}/MyProfile/`} component={Companies} />
          <Route path={`https://nandwsouk.com/`} component={Abonement} />
          <Route path={`${path}/Curriculum/`} component={Curriculum} />
          <Route path={`${path}/Navitech/`} component={Navitech} />
          <Route path={`${path}/Suppliers/:id/AskQuestion/`} component={AskQuestion} />

          <Route
            path={`${path}/tutorcategory/:category?`}
            render={(props) => <Tutorcategory {...props} />}
          />
          <Route
            path={`${path}/blog/:category?`}
            render={(props) => <Blog {...props} />}
          />
          <Route
            path={`${path}/checkout`}
            render={(props) => <Checkout {...props} />}
          />
          <Route path={`${path}/pay`} render={(props) => <Pay {...props} />} />
          <Route
            path={`${path}/blogPost`}
            render={(props) => <BlogPost {...props} />}
          />
          <Redirect to="/" />
        </Switch>
      </div>
    </Fragment>
  );
};

export default Layout;
