import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
const useStyles = makeStyles((theme) => ({
  wrapper: {
    [theme.breakpoints.down(900)]: {
      width: "100% !important",
      margin: "auto !important",
    },
  },
  arshive: {
    width: "100%",
    minHeight: 250,
    backgroundColor: "white",
    padding: 20,
    textAlign: "left",
    boxShadow: "0 2px 4px 0 rgba(115,167,228,.4)",
    marginBottom: "1rem",
  },
  title: {
    fontSize: 23,
    fontWeight: 800,
    lineHeight: 1.2,
    color: "#232f49",
  },
  number: {
    color: "#d50951",
    fontSize: "1.5rem",
    fontWeight: 800,
    "&:hover": {
      color: "#b60946",
    },
  },
}));
const Arshive = () => {
  const classes = useStyles();
  return (
    <div className={`w-1/4 mx-2 ${classes.wrapper}`}>
      <div className={classes.arshive}>
        <img
          alt="Eurasia Eco"
          src="https://cdn.tutorful.co.uk/assets/homepage/online-with-play-button.jpg"
        />
        <h4 className={`mt-4 mb-5 ${classes.title}`}>
          <FormattedMessage
            id="app.arshive.title1"
            defaultMessage="How does online tutoring work"
          />
        </h4>
        <p className="leading-6 text-gray-700">
          <FormattedMessage id="app.arshive.subtitle1" />
        </p>
      </div>
      <div className={classes.arshive}>
        <h4 className={`mt-4 mb-5 ${classes.title}`}>
          <FormattedMessage id="app.arshive.title2" />
        </h4>
        <p className="leading-6 text-gray-700 mb-6">
          <FormattedMessage id="app.arshive.subtitle2" />
        </p>
        <a href="tel:09030525589" className={classes.number}>
          +7 985 077 1042
        </a>
        <p className="mb-0 mt-3 leading-6">
          <span className="font-extrabold ">
            <FormattedMessage id="app.arshive.daies" />
          </span>
          <br />
          09:00 - 19:00
        </p>
      </div>
    </div>
  );
};

export default Arshive;
