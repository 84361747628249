import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    padding: 20,
    flexDirection: "column",
    alignItems: "center",
    background: "white",
    borderRadius: 12,
    textAlign: "center",
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)",
    height: "auto",
    maxWidth: 310,
    margin: 20,
    transition:"0.3s",
    top:0,
    position:"relative",
    cursor:"pointer",
    "&:hover":{
      top:-5,
      transition:"0.3s",
    },
    [theme.breakpoints.down(600)]:{
      width:"100%",
      maxWidth:"100%"
    }
  },
  buttonside: {
    border: "2px solid #1062D8 ",
    backgroundColor: "white",
    borderRadius: 100,
    width: 150,
    height: 45,
    color: "#1062D8",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  blue: {
    backgroundColor: "#1062D8",
    color: "white",
    borderRadius: 100,
    width: 150,
    height: 45,
    "&:hover": {
      backgroundColor: "#1062D8",
    },
  },
  img:{
    marginTop: -15,
    width:230,
    [theme.breakpoints.down(600)]:{
      width:"60%"
    }
  }
}));
const LessonsCards = (props) => {
  const classes = useStyles();
  return (
    <div className="w-full sm:w-1/3 flex justify-center">
      <div className={classes.card}>
        <img className={classes.img} src={props.img} />
        <h3 className="text-xl font-semibold mb-4">{props.title}</h3>
        <p className="text-gray-700 mb-4">{props.text}</p>
        <Button className={props.bluebtn ? classes.blue : classes.buttonside}>
          {props.button}
        </Button>
      </div>
    </div>
  );
};

export default LessonsCards;
