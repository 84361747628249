import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "./Card";
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SubjectAction } from "store/searchBySubjects";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1200,
    margin: "auto",
  },
  card: {
    width: 200,
    maxHeight: 150,
    padding: "10px 0",
    backgroundColor: "white",
    boxShadow: "0 2px 4px rgba(115, 166, 227, 0.4)",
    margin: 10,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down(900)]: {
      width: 100,
    },
  },
  title: {
    color: "#232f49",
    padding: "50px 0",
    fontSize: "1.3rem",
    fontWeight: 700,
    [theme.breakpoints.down(900)]: {
      textAlign: "center",
    },
  },
  error: {
    color: "#d50951",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 35,
    marginTop: 100,
  },
}));

const Cards = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const Category = useSelector((state) => state.searchC);

  const subjecthandler = (item) => {
    dispatch(SubjectAction(item));
  };

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>
        <FormattedMessage id="Search.app.witchone" defaultMessage="WitchOne?" />
      </h2>
      <div className="flex flex-wrap w-full">
        {!Category.data.data
          ? null
          : Category.data.data.map((item, i) => {
            return (
              <div
                onClick={() => subjecthandler(item.title)}
                key={i}
                className={classes.card}
              >
                <Card title={item.description}
                  title={item.title}
                  img={"https://payapars.com/website/content/uploads/2018/11/ui-and-ux-deisgn.jpg"}
                //  img={"https://superadmin.nandwsouk.com/"+item.imageSrc} 
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default injectIntl(Cards);
