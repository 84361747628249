import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
const useStyles = makeStyles((theme) => ({
  star: {
    fontSize: 25,
    color: "#f8be15",
    [theme.breakpoints.down(675)]: {
      fontSize: 14,
    },
  },
  profile: {
    display: "flex",
    width: 200,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  profileimg: {
    width: 60,
    height: 60,
    backgroundColor: "#d50951",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontWeight: 600,
    fontSize: 25,
    [theme.breakpoints.down(675)]: {
      width: 40,
      height: 40,
      fontSize: 15,
    },
  },
  date: {
    fontSize: 13,
    [theme.breakpoints.down(675)]: {
      fontSize: 10,
    },
  },
}));
const Review = (props) => {
  const classes = useStyles();
  const Fisrtleters = props.name.match(/\b(\w)/g);
  const alt = Fisrtleters.join("");
  return (
    <div className="flex">
      <div
        className={`flex-initial text-gray-700 text-center py-2 m-2 ${classes.profile}`}
      >
        <div className={classes.profileimg}>
          <img alt={alt} src="" />
        </div>
        <p className="mt-1">{props.name}</p>
      </div>
      <div className="flex-initial text-gray-700 px-4 py-2 m-2 text-left">
        <div className="mb-4">
          <Rating value={props.rate} className={classes.star} readOnly />
        </div>
        <p className="leading-6">{props.comment}</p>
        <p className={`mt-6 ${classes.date}`}>{props.date}</p>
      </div>
    </div>
  );
};

export default Review;
