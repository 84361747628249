import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Tooltip } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getLearners } from "store/bestTutors";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.down(600)]: {
      display: "block",
    },
  },
  cardbox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: "0 0 20%",
    marginBottom: 32,
    margin: 5,
    padding: 10,
  },
  cardimg: {
    width: 200,
    marginBottom: 16,
    border: "3px solid rgb(0, 204, 136)",
    borderRadius: 5,
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 7px",
  },
  icon: {
    width: 20,
    height: 20,
    margin: "0px 5px",
  },
  name: {
    color: "black",
    padding: "0.5em 0",
    fontSize: 16,
    fontWeight: 500,
  },
  text: {
    fontSize: 12,
    textAlign: "center",
    lineClamp: 2,
    boxOrient: "vertical",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  showMorebtn: {
    display: "flex",
    margin: "auto",
    backgroundColor: "rgb(0, 204, 136)",
    color: "white",
    width: 150,
    height: 40,
    padding: "15px 28px 15px",
    fontSize: 14,
    borderRadius: 30,
    "&:hover": {
      backgroundColor: "rgb(2, 163, 120)",
    },
  },
}));
const Card = () => {
  const classes = useStyles();
  const bestTutors = useSelector((state) => state.bestTutors);
  const dispatch = useDispatch();
  const visible = 5;
  const [visibility, setvisibility] = useState(visible);

  useEffect(() => {
    dispatch(getLearners());
  }, []);

  const ShowMore = () => {
    setvisibility((prev) => {
      return prev + 4;
    });
  };

  return (
    <div>
      <div className={classes.wrapper}>
        {!bestTutors.data.data
          ? null
          : bestTutors.data.data.slice(0, visibility).map((item, i) => (
              <div key={i} className={classes.cardbox}>
                <img
                  className={classes.cardimg}
                  src={`https://superadmin.nandwsouk.com/admin/${item.masterImage}`}
                />
                <div className="flex">
                  <Tooltip placement="top" title="Tutor is verified">
                    <img
                      alt="icon"
                      className={classes.icon}
                      src="https://tutor.id/static/svg/icon-verified.svg"
                    />
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={`English as a teaching language`}
                  >
                    <img
                      alt={item.language}
                      className={classes.icon}
                      src="https://tutor.id/static/svg/flag-uk.svg"
                    />
                  </Tooltip>
                </div>
                <a href={`#${item.masterName}`} className={classes.name}>
                  {item.masterName}
                </a>
                <p className={classes.text}>{item.masterDescription}</p>
              </div>
            ))}
      </div>
      <Button
        hidden={
          !bestTutors.data.data
            ? false
            : visibility >= bestTutors.data.data.length
            ? true
            : false
        }
        onClick={ShowMore}
        className={classes.showMorebtn}
      >
        <FormattedMessage
          id="landing.app.ShowMore"
          defaultMessage="Show More"
        />
      </Button>
    </div>
  );
};

export default Card;
