import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 60,
    height: 60,
    margin: "auto",
    [theme.breakpoints.down(750)]: {
      display: "none",
    },
  },
}));

const Card = (props) => {
  const [showimg, setshowimg] = useState(true);
  const classes = useStyles();
  return (
    <div className="p-2 flex-wrap">
      {!props.img ? (
        ""
      ) : (
        <img alt={props.title} className={classes.icon} src={props.img} />
      )}
      <p className="mt-2 font-semibold text-xs text-center">{props.title}</p>
    </div>
  );
};

export default Card;
