import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundImage: "url(https://portfoman.ir/static/images/header/trainingbg.jpg)",
    color: "white",
    padding: "150px 20px 144px",
    [theme.breakpoints.down(1000)]: {
      padding: "50px 0 144px",
    },
  },
  wrapper: {
    position: "relative",
    maxWidth: 1200,
    margin: "auto",
    display: "flex",
  },
  boldtitle: {
    width: 400,
    fontWeight: 600,
    fontSize: 38,
    marginBottom: 15,
    [theme.breakpoints.down(600)]: {
      fontWeight: 600,
      fontSize: 30,
    },
  },
  subhead: {
    fontSize: 22,
    marginBottom: 40,
    [theme.breakpoints.down(600)]: {
      fontSize: 16,
    },
  },
  head: {
    width: "55%",
    fontSize: 20,
    [theme.breakpoints.down(1000)]: {
      width: "100%",
      fontSize: 15,
      margin: "auto",
      padding: 20,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  searchinput: {
    height: 50,
    padding: 15,
    borderTopLeftRadius: 100,
    borderBottomLeftRadius: 100,
    color: "black",
    "&:focus": {
      border: "2px solid rgb(0, 204, 136)",
    },
  },
  searchbtn: {
    width: 200,
    backgroundColor: "rgb(0, 204, 136)",
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
  },
  listItem: {
    fontSize: 15,
    borderBottom: "1px solid white",
    [theme.breakpoints.down(1000)]: {
      display: "flex",
      justifyContent: "center",
    },
    "&:before": {
      content: "''",
      background: "url(https://tutor.id/static/svg/icon-check.svg)",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      width: 27,
      height: 27,
      position: "absolute",
      left: -40,
      display: "block",
    },
  },
  cup: {
    position: "absolute",
    bottom: 0,
    right: 0,
    top:"31%",
    width: 300,
    textAlign: "center",
    fontWeight: "bold",
    [theme.breakpoints.down(1000)]: {
      left: 0,
      right: 0,
      margin: "auto",
      position: "relative",
      width: "auto",
      maxWidth: 450,
    },
  },
  cuptext: {
    fontSize: 19,
    lineHeight: "1.5em",
    marginBottom: "1.5em",
  },
}));

const HeadBanner = ({ intl }) => {
  const [Search, setSearch] = useState("");
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.head}>
          <h1 data-aos="fade-up" className={classes.boldtitle}>
            <FormattedMessage id="Tutors.app.header" />
          </h1>
          <h1 data-aos="fade-up" className={classes.boldtitle}>
            <FormattedMessage id="Tutors.app.subhead" />
          </h1>
          <p className={classes.subhead}>
            <FormattedMessage id="Tutors.app.header.subhead" /> 
          </p>
          <div>
            <div className="flex">
              <input
                value={Search}
                onChange={(e) => setSearch(e.target.value)}
                className={`w-full ${classes.searchinput}`}
                type="text"
                placeholder={intl.formatMessage({
                  id: "Tutors.app.header.input",
                  defaultMessage: "Witch subject?",
                })}
              />
              <button className={classes.searchbtn}>
                <FormattedMessage
                  id="header.tutorcategory"
                  defaultMessage="Search"
                />
              </button>
            </div>
            <ul data-aos="fade-up" className="my-6 mx-10">
              {intl.messages.TutorsLinkLists.map((item, i) => {
                return (
                  <li key={i} className="relative mt-6">
                    <a className={classes.listItem} href={item.link}>
                      {item.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div data-aos="fade-up" className={`mt-8 ${classes.cup}`}>
            <p className={classes.cuptext}>
              <FormattedMessage id="Tutors.app.header.cup" />
            </p>
            <img
              alt="logo"
              src="https://tutor.id/static/images/img-holon.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default injectIntl(HeadBanner);
