import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CountUp from "react-countup";
const useStyles = makeStyles((theme) => ({
  column: {
    flex: 1,
    color: "black",
    borderRight: "1px solid #efefef",
    textAlign: "center",
    [theme.breakpoints.down(800)]:{
      width:"50%",
      flex: "auto",
      margin:"10px 0"
    }
  },
  number_item_top: {
    fontSize: 64,
    fontWeight: "500",
    [theme.breakpoints.down(750)]: {
      fontSize: 20,
      display: "block ruby",
    },
  },
  span: {
    fontSize: 18,
    padding: 15,
    fontWeight: 500,
  },
  text: {
    fontSize: 18,
    marginTop: 10,
    fontWeight: "600",
    [theme.breakpoints.down(750)]: {
      fontSize: 12,
      fontWeight: "500",
    },
  },
  bgwhite:{
    padding:20,
    backgroundColor:"white",
    marginTop:20,
    borderRadius:10,
    boxShadow:"0 2px 4px rgba(115, 166, 227, 0.4)"
  }
}));

const Count = (props) => {
  const classes = useStyles();
  const [visible, setvisible] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", function (e) {
      toggleVisibility();
    });
  }, []);

  const toggleVisibility = () => {
    if (window.pageYOffset > 1200) {
      setvisible(true);
    } else {
      setvisible(false);
    }
  };
  return (
    <div className={classes.column}>
      <div className={classes.number_item_top}>
        <span className={classes.span}>  </span>
        <b className="spincrementyear">
          {!visible ? (
            "0"
          ) : (
            <CountUp
              delay={0}
              duration={10}
              start={props.start}
              end={props.end}
            />
          )}
        </b>
        <span className={classes.span}>{props.icon}</span>
      </div>
      <p className={classes.text}>{props.text}</p>
    </div>
  );
};

const Countes = (props) => {
  const classes =useStyles()
  return (
    <div className={`flex flex-wrap p-mobilesize mt-16 ${props.white && classes.bgwhite}`}>
      <Count icon="year" text="14 years" start={2008} end={2022} />
      <Count
        icon="+"
        text="Suppliers"
        start={0}
        end={3854}
      />
      <Count icon="+" text="Completed orders" start={0} end={62105} />
      <Count icon="+" text="Products" start={0} end={31532} />
    </div>
  );
};

export default Countes;
