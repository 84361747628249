import React, { useEffect, useMemo, useState } from "react";
import Cards from "components/componies/Cards";
import HeadBanner from "components/componies/Headbanner";
import { useDispatch, useSelector } from "react-redux";
import {
  SearchValueAction,
  SubjectValueAction,
  CategoryValueAction,
  PageValueAction,
} from "store/searchinput";
import { getLearners } from "store/bestTutors";
import { useRouteMatch } from "react-router-dom";
import { debounce } from "lodash";
import Buttons from "components/componies/Buttons";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1100,
    margin: "auto",
    height: 80,
    display: "flex",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down(1000)]:{
      justifyContent:"center"
    }
  },
  pagin: {
    maxWidth: 550,
    boxShadow: "0 2px 4px 0 rgba(115,167,228,.4)",
    position: "absolute",
    right: 0,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
    padding: 10,
    margin: "auto 8px",
    [theme.breakpoints.down(1000)]:{
      right:"auto"
    }
  },
  "@global": {
    ".MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "rgba(115,167,228,.2)",
      color: "blue",
      fontWeight: 700,
      border: "2px solid rgba(115,167,228,.8)",
    },
    ".MuiPaginationItem-outlined": {
      width: 55,
      height: 35,
      fontWeight: 600,
      backgroundColor: "rgba(115,167,228,.01)",
      color: "gray",
      border: "2px solid rgba(115,167,228,.2)",
      [theme.breakpoints.down(700)]:{
        width: 30,
        height: 30,
      }
    },
    ".MuiPaginationItem-icon": {
      color: "black",
    },
  },
}));
function Companies() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const SearchVal = useSelector((state) => state.Searchvalue);
  const match = useRouteMatch({ path: "/:language/:city?" });
  const { language, city = "all-country" } = match.params;
  useEffect(
    (e) => {
      window.history.pushState(
        match,
        "Title",
        `/${language}/${city}/myProfile/${Pagenumber}|`
      );
      dispatch(getLearners(e, language, city));
    },
    [city, language]
  );

  const delayedCallback = debounce((value) => {
    dispatch(getLearners(value, language, city));
  }, 500);

  const searchByName = (event) => {
    window.history.pushState(
      match,
      "Title",
      `/${language}/${city}/myProfile/${1}|`
    );
    dispatch(SearchValueAction(event.target.value));
    delayedCallback({
      searchText: event.target.value,
      subject: SearchVal.subjectvalue,
      category: SearchVal.categoryvalue,
      price: SearchVal.pricevalue,
      rate: SearchVal.ratevalue,
    });
    dispatch(PageValueAction(1));
    setPagenumber(1);
  };

  const handleSubjectOnClick = (event) => {
    dispatch(SubjectValueAction(event.target.value));
    delayedCallback({
      searchText: SearchVal.value,
      subject: event.target.value,
      category: SearchVal.categoryvalue,
      price: SearchVal.pricevalue,
      rate: SearchVal.ratevalue,
    });
  };
  const handleCategoryOnClick = (event, value) => {
    const val = value && value.slug ? value.slug : "";
    dispatch(CategoryValueAction(val));
    delayedCallback({
      searchText: SearchVal.value,
      subject: SearchVal.subjectvalue,
      price: SearchVal.pricevalue,
      rate: SearchVal.ratevalue,
      category: val,
    });
    window.history.pushState(
      match,
      "Title",
      `/${language}/${city}/myProfile/${Pagenumber}|${val}/`
    );
  };
  const [Pagenumber, setPagenumber] = React.useState(1);
  const handleChange = (event, value) => {
    setPagenumber(() => {
      const newstate = value;
      dispatch(PageValueAction(newstate));
      delayedCallback({
        searchText: SearchVal.value,
        subject: SearchVal.subjectvalue,
        category: SearchVal.categoryvalue,
        price: SearchVal.pricevalue,
        rate: SearchVal.ratevalue,
        skip: newstate - 1,
      });
      window.history.pushState(
        match,
        "Title",
        `/${language}/${city}/myProfile/${newstate}|`
      );
      return newstate;
    });
  };
  const bestTutors = useSelector((state) => state.bestTutors);
  const pageCount = useMemo(() => {
    return Math.ceil(bestTutors?.data?.cnt / 10);
  }, [bestTutors?.data?.cnt, 10]);
  return (
    <div>
      <HeadBanner
        searchByName={searchByName}
        subjectOnClick={handleSubjectOnClick}
        categoryOnClick={handleCategoryOnClick}
      />
      <Buttons />
      <div className={classes.root}>
        <div className={classes.pagin}>
          <Pagination
            rowsperpage={1}
            variant="outlined"
            shape="rounded"
            count={pageCount}
            page={Pagenumber}
            onChange={handleChange}
          />
        </div>
      </div>
      <Cards />
    </div>
  );
}

export default Companies;
