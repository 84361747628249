import {
  COMMENTS_FAILED,
  COMMENTS_LOADING,
  COMMENTS_SUCCESS,
} from "./tutorcomment.action";

const initialState = {
  loading: false,
  data: [],
  error: null,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMENTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case COMMENTS_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.data,
      };
    case COMMENTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export default reducer;
