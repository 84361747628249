import { VALUE } from "./login.action";

const initialState = {
  code: "",
  mobile: "",
  email: "",
  pass: "",
  verifyCode: "",
  lang: "fa",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case VALUE:
      return {
        ...state,
        code: action.code,
        mobile: action.mobile,
        email: action.email,
        pass: action.pass,
        verifyCode: action.verifyCode,
      };
    default:
      return state;
  }
};
export default reducer;
