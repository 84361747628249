export const SUBJECT = "SUBJECT";
export const LEVEL = "LEVEL";
export const WHERE = "WHERE";
export const WEEK = "WEEK";

const Subject = (subjects) => ({
  type: SUBJECT,
  subjects: subjects,
});
const Level = (level) => ({
  type: LEVEL,
  level: level,
});
const Where = (where) => ({
  type: WHERE,
  where: where,
});
const Week = (week) => ({
  type: WEEK,
  week: week,
});

export const SubjectAction = (newstate) => {
  return (dispatch) => {
    dispatch(Subject(newstate));
  };
};
export const LevelAction = (newstate) => {
  return (dispatch) => {
    dispatch(Level(newstate));
  };
};
export const WhereAction = (newstate) => {
  return (dispatch) => {
    dispatch(Where(newstate));
  };
};
export const WeekAction = (newstate) => {
  return (dispatch) => {
    dispatch(Week(newstate));
  };
};
