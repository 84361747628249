import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 650,
    width: "auto",
  },
  questionBox: {
    width: "100%",
    backgroundColor: "#fff",
    boxShadow: "0 1px 2px rgba(0, 0, 0, .14)",
    borderRadius: 10,
    marginBottom: 10,
    padding: 38,
    height:100,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: 22,
    position: "relative",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&:before": {
      content: "''",
      position: "absolute",
      left: 0,
      top: 0,
      height: "100%",
      width: "1%",
      backgroundColor: "#ffcf85",
    },
    [theme.breakpoints.down(900)]: {
      height: 80,
      fontSize: 13,
    },
  },
  arrowbtn: {
    cursor: "pointer",
    transition: ".3s",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50,
    backgroundColor: "#f9f8f6",
    borderRadius: "50%",
    "&:before": {
      content: "''",
      position: "absolute",
      zIndex: 0,
      transition: ".3s",
    },
    [theme.breakpoints.down(900)]: {
      width: 40,
      height: 40,
    },
  },
  down: {
    transform: "rotate(180deg)",
    transition: "transform 0.2s linear",
  },
  up: {
    transform: "rotate(0deg)",
    transition: "transform 0.2s linear",
  },
}));
const Results = (props) => (
  <p className="p-10 text-gray-700 leading-8">{props.res}</p>
);

const Question = (props) => {
  const [showResults, setShowResults] = useState(false);
  const ShowMore = () => setShowResults(!showResults);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div data-aos="fade-up" className={classes.questionBox}>
        <div>
          <p className="text-sm" >{props.title}</p>
        </div>
        <div onClick={ShowMore} className={classes.arrowbtn}>
          <img
            alt="Eurasia Eco"
            className={showResults ? classes.up : classes.down}
            src="/static/img/q_arrow.svg"
          />
        </div>
      </div>
      {showResults ? <Results res={props.text} /> : null}
    </div>
  );
};

export default Question;
