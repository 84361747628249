import { combineReducers } from "redux";

import currencyReducer from "./currency";
import localeReducer from "./locale";
import dialogReducer from "./dialog";
//import restaurantReducer from './restaurant';
import cityReducer from "./city";
import locationReducer from "./location";
import popoverReducer from "./popover";
import tutorReducer from "./tutor";
import profileReducer from "./profile";
import tutorcategoryReducer from "./tutorcategory";
import userReducer from "./user";
import timeReducer from "./time";
import menuReducer from "./menu";
import settingReducer from "./setting";
import blogReducer from "./blog";
import blogPostReducer from "./blogPost";
import contactReducer from "./contact";
import landingReducer from "./landing";
import priceReducer from "./price";
import bestTutors from "./bestTutors";
import SearchCategory from "./searchCategory";
import subject from "./subject";
import Star from "./star";
import searchvl from "./searchinput";
import TutorDetail from "./tutorsDetail";
import TutorComments from "./TutorsComment";
import SubjectReucer from "./searchBySubjects";
import LoginReducer from "./login";
import ReqLesson from "./requestLesson";
export default combineReducers({
  currency: currencyReducer,
  locale: localeReducer,
  dialog: dialogReducer,
  // restaurant	: restaurantReducer,
  city: cityReducer,
  location: locationReducer,
  popover: popoverReducer,
  tutor: tutorReducer,
  profile: profileReducer,
  tutorcategory: tutorcategoryReducer,
  user: userReducer,
  timeList: timeReducer,
  menu: menuReducer,
  setting: settingReducer,

  blog: blogReducer,
  blogPost: blogPostReducer,
  contact: contactReducer,
  landing: landingReducer,
  price: priceReducer,
  bestTutors: bestTutors,
  searchC: SearchCategory,
  SearchSubject: subject,
  Star: Star,
  Searchvalue: searchvl,
  Detail: TutorDetail,
  Comments: TutorComments,
  SubjectSearch: SubjectReucer,
  Login:LoginReducer,
  ReqLesson:ReqLesson
});
