import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { levelReqAction, subjectReqAction } from "store/requestLesson";
const data = [
  { title: "The Shawshank " },
  { title: " Godfather" },
  { title: "The God II" },
  { title: "The Dark " },
  { title: "12 Men" },
];

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiAutocomplete-inputRoot": {
      borderRadius: 0,
    },
    ".MuiInputBase-root": {
      height: "40px !important",
      backgroundColor:"white"
    },
    ".MuiInputBase-input ":{
      marginTop:-8,
      fontSize:12
    },
    ".MuiFormLabel-root": {
      marginTop: -6,
    },
  },
}));
const ChooseLearn = () => {
  useStyles()
  const dispatch = useDispatch();
  const ReqLesson = useSelector((state) => state.ReqLesson);
  const handleSubject = (event, value) => {
    dispatch(subjectReqAction(value === null ? null : value.title));
  };
  const handleLevel = (event, value) => {
    dispatch(levelReqAction(value === null ? null : value.title));
  };
  return (
    <div className="mt-8">
      <h6 className="font-bold text-base text-gray-700">
        What would you like to learn?
      </h6>
      <div className="flex mt-6">
        <Autocomplete
          id="Subject"
          onChange={handleSubject}
          options={data}
          getOptionLabel={(option) => option.title}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Subject" variant="outlined" />
          )}
        />
        <Autocomplete
          disabled={!ReqLesson.subject && true}
          id="Level"
          options={data}
          onChange={handleLevel}
          getOptionLabel={(option) => option.title}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Level" variant="outlined" />
          )}
        />
      </div>
    </div>
  );
};

export default ChooseLearn;
