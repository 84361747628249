export const PRICE = "PRICE";
const price = (price) => ({
  type: PRICE,
  price: price,
});
export const PriceAction = (newstate) => {
  return (dispatch) => {
    dispatch(price(newstate));
  };
};
