import React from "react";
import Title from "components/landing/Title";
import { makeStyles } from "@material-ui/core/styles";
import LessonsCards from "./LessonsCards";
const useStyles = makeStyles((theme) => ({
  root: {
    background: "rgba(230,244,254)",
    paddingBottom: 200,
  },
  wrapper: {
    maxWidth: 1150,
    margin: "auto",
    textAlign: "center",
  },
}));
const InternetLessens = (props) => {
  const classes = useStyles();
  return (
    <div className={`${classes.root} pt-24`}>
      <div className={classes.wrapper}>
        <Title black="header.InternetLessens" blue="header.is" />
        <div className="flex flex-wrap">
          <LessonsCards
            img="https://interneturok.ru//static/img/linkblock-1.png"
            title="Title Text"
            text="Video lessons, notes, tests, simulators"
            button="Go To"
            bluebtn={false}
          />
          <LessonsCards
            img="https://interneturok.ru//static/img/linkblock-1.png"
            title="Title Text"
            text="Video lessons, notes, tests, simulators"
            button="Go To"
            bluebtn={true}
          />
          <LessonsCards
            img="https://interneturok.ru//static/img/linkblock-1.png"
            title="Title Text"
            text="Video lessons, notes, tests, simulators"
            button="Go To"
            bluebtn={false}
          />
        </div>
      </div>
    </div>
  );
};

export default InternetLessens;
