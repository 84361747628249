import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import PlaceIcon from "@material-ui/icons/Place";
import { useDispatch } from "react-redux";
import {
  levelReqAction,
  placeReqAction,
  postcodeReqAction,
} from "store/requestLesson";

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: "white",
    boxShadow: "0 2px 4px rgba(115, 166, 227, 0.4)",
    border: "1px solid #e2e2e2",
    color: "#232f49",
    width: 180,
    margin: "10px 0",
    height: 55,
    fontWeight: 900,
    textTransform: "capitalize",
  },
  active: {
    backgroundColor: "white",
    boxShadow: "0 2px 4px rgba(115, 166, 227, 0.4)",
    color: "#4cbcc2",
    width: 180,
    fontWeight: 900,
    margin: "10px 0",
    height: 55,
    border: "1px solid #4cbcc2",
    textTransform: "capitalize",
  },
  postcodeinput: {
    border: "1px solid #e2e2e2",
    backgroundColor: "white",
    width: "max-content",
    borderRadius: 4,
  },
  placeicon: {
    color: "gray",
    fontSize: 16,
  },
  input: {
    backgroundColor: "white",
    padding: "5px 0",
    width: 220,
    fontSize: 15,
    "&::placeholder": {
      color: "black",
      fontStyle: "italic",
    },
  },
}));
const WhereLearn = () => {
  const [active, setactive] = useState("Online");
  const [postcode, setpostcode] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const locationHandler = (item) => {
    setactive(item);
    dispatch(placeReqAction(item));
  };
  const handleChangePostCode = (e) => {
    setpostcode(e.target.value);
    dispatch(postcodeReqAction(e.target.value));
  };
  return (
    <div className="mt-6">
      <h6 className="font-bold text-base text-gray-700">
        Where would you like your lessons?
      </h6>
      <div className="flex">
        <Button
          onClick={() => locationHandler("Online")}
          className={active === "Online" ? classes.active : classes.box}
        >
          Online
        </Button>
        <Button
          onClick={() => locationHandler("My home")}
          className={`${
            active === "My home" ? classes.active : classes.box
          } mx-4`}
        >
          My home
        </Button>
        <Button
          onClick={() => locationHandler("Tutor's home")}
          className={active === "Tutor's home" ? classes.active : classes.box}
        >
          Tutor's home
        </Button>
      </div>
      {active !== "Online" && (
        <div data-aos="fade-down" className="mt-6">
          <h6 className="font-bold text-base text-gray-700">
            Your postcode is…
          </h6>
          <div className={`${classes.postcodeinput} mt-4 px-2`}>
            <input
              value={postcode}
              onChange={handleChangePostCode}
              className={classes.input}
              placeholder="E.g. S7 1DF"
            />
            <PlaceIcon className={classes.placeicon} />
          </div>
        </div>
      )}
    </div>
  );
};

export default WhereLearn;
