import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Question from "./Question";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1000,
    margin: "auto",
    paddingTop: 30,
    paddingBottom: 20,
  },
  title: {
    fontSize: 30,
    fontWeight: 500,
    width: "50%",
    [theme.breakpoints.down(900)]: {
      fontSize: 30,
      width: "100%",
    },
  },
  arrow: {
    width: 35,
    height: "60%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(255, 255, 255, .2)",
    borderRadius: " 50%",
    transition: "0.3s",
    marginLeft: 10,
  },
  wrapper: {
    backgroundImage: "url(/static/img/woooman.png)",
    backgroundPositionX: "135%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "58%",
    [theme.breakpoints.down(900)]: {
      backgroundImage: "none",
    },
  },
  seeAllbtn: {
    transition: "0.5s",
    background: "#ae0022",
    color: "#fff",
    width: 400,
    height: 50,
    "text-transform": "uppercase",
    borderRadius: 8,
    margin: "3em 0",
    boxShadow: "0 4px 10px rgba(246, 134, 31, .38)",
    "&:hover": {
      transition: "0.5s",
      background: "#d00003",
    },
    [theme.breakpoints.down(900)]: {
      maxWidth: 250,
      height: 40,
    },
    [theme.breakpoints.down(350)]: {
      width: 200,
      fontSize: 12,
      margin: "auto",
      padding: 25,
    },
  },
}));

const Questions = () => {
  const classes = useStyles();
  return (
    <div
      id="section_6"
      data-aos="fade-up"
      className={`${classes.root} p-8 md:p-0`}
    >
      <div className={classes.wrapper}>
        <div data-aos="fade-up" className={`${classes.title} md:mt-32 mb-8`}>
          <FormattedMessage id="landing.app.AskQuestion" />
          <span style={{ color: "#ffaf35", padding: "0.2em" }}>
            <FormattedMessage id="landing.app.AskQuestionOrange" />
          </span>
        </div>
        <div data-aos="fade-up">
          <Question
            title={<FormattedMessage id="landing.app.Question1" />}
            text={<FormattedMessage id="landing.app.Ask1" />}
          />
          <Question
            title={<FormattedMessage id="landing.app.Question2" />}
            text={<FormattedMessage id="landing.app.Ask2" />}
          />
          <Question
            title={<FormattedMessage id="landing.app.Question3" />}
            text={<FormattedMessage id="landing.app.Ask3" />}
          />
        </div>
        <button
          className={`relative flex justify-center items-center ${classes.seeAllbtn}`}
        >
          <FormattedMessage id="home.nextRestaurants.ShowMore" />
          <span className={classes.arrow}>
            <img
              alt="arrow"
              src="/static/img/whitearrow.svg"
            />
          </span>
        </button>
      </div>
    </div>
  );
};

export default Questions;
