import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    background: "#e5f3fe",
    position: "relative",
    "&:after": {
      position: "absolute",
      top: -21,
      width: "100%",
      height: 250,
      content: "''",
      transform: "rotate(180deg)",
      backgroundImage:
        "url(https://interneturok.ru//static/img/section_bg-c.svg)",
    },
  },
  wrapper: {
    maxWidth: 1000,
    margin: "auto",
    textAlign: "center",
    padding: "150px 0",
  },
  cardBox: {
    backgroundColor: "white",
    minHeight: 400,
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: 12,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.14)",
    textAlign: "center",
    padding: "20px",
    transition: "0.3s",
    top: 0,
    position: "relative",
    cursor: "pointer",
    "&:hover": {
      top: -5,
      transition: "0.3s",
    },
    "&:after": {
      content: "''",
      display: "block",
      position: "absolute",
      background: "#db2b21",
      bottom: 0,
      left: 0,
      right: 0,
      width: "100%",
      height: 10,
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
    },
  },
  centerCardBox: {
    backgroundColor: "white",
    minHeight: 400,
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: 12,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.14)",
    textAlign: "center",
    padding: "20px",
    transition: "0.3s",
    top: 0,
    position: "relative",
    cursor: "pointer",
    "&:hover": {
      top: -5,
      transition: "0.3s",
    },
    "&:after": {
      content: "''",
      display: "block",
      position: "absolute",
      background: "#FE9023",
      bottom: 0,
      left: 0,
      right: 0,
      width: "100%",
      height: 10,
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
    },
  },
  backdrop: {
    width: 220,
    objectFit: "contain",
  },
  border: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    margin: "10px 0",
    width: "100%",
    height: 1,
  },
  btnbox: {
    marginTop: 15,
    height: 50,
    display: "flex",
    alignItems: "center",
  },
  button: {
    backgroundColor: "#db2b21",
    padding: "10px 20px",
    height: 40,
    color: "white",
    borderRadius: 100,
    transition: "0.3s",
    "&:hover": {
      backgroundColor: "#db2b21",
      padding: "15px 50px",
      transition: "0.3s",
    },
  },
}));

const Tariffs = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <h3 className="text-4xl font-bold">Tariffs</h3>
        <div className="flex flex-wrap mt-32">
          <div className="w-full md:w-1/3 p-2">
            <div className={`text-center ${classes.cardBox}`}>
              <img
                className={classes.backdrop}
                alt="backdrop"
                src="https://interneturok.ru//static/img/formedu-1.png"
              />
              <h4 className={`text-lg font-bold leading-6`}>
                Title <br />
                <span style={{ color: "#db2b21" }}>Blue</span>
              </h4>
              <div className={classes.border} />
              <p className="text-gray-600 text-xs px-8">
                All sections and services, not included in the Subscription, as
                well as lessons with marker "Free access"
              </p>
              <div className={classes.btnbox}>
                <Button className={classes.button}>Btn</Button>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/3 p-2 md:-my-20">
            <div className={`text-center ${classes.cardBox}`}>
              <img
                className={classes.backdrop}
                alt="backdrop"
                src="https://interneturok.ru//static/img/formedu-1.png"
              />
              <h4 className={`text-lg font-bold leading-6`}>
                Title <br />
                <span style={{ color: "#db2b21" }}>Blue</span>
              </h4>
              <div className={classes.border} />
              <h5 className="text-2xl font-bold">
                250 / hr <span className="text-xs font-normal">per month</span>
              </h5>
              <div className={classes.border} />
              <p className="text-gray-600 text-xs px-8">
                All sections and services, not included in the Subscription, as
                well as lessons with marker "Free access" All sections and
                services, not included in the Subscription, as well as lessons
                with marker "Free access"
              </p>
              <div className={classes.btnbox}>
                <Button className={classes.button}>Btn</Button>
              </div>
              <div className={classes.border} />
              <p
                style={{ color: "#4E90CF" }}
                className="text-gray-600 text-xs px-8"
              >
                Show More <span className="text-lg">↓</span>
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/3 p-2">
            <div className={`text-center ${classes.centerCardBox}`}>
              <img
                className={classes.backdrop}
                alt="backdrop"
                src="https://interneturok.ru//static/img/formedu-1.png"
              />
              <h4 className={`text-lg font-bold leading-6`}>
                Title <br />
                <span style={{ color: "#FE9023" }}>Orange</span>
              </h4>
              <div className={classes.border} />
              <p className="text-gray-600 text-xs px-8">
                All sections and services, not included in the Subscription, as
                well as lessons with marker "Free access"
              </p>
              <div className={classes.btnbox}>
                <Button
                  style={{ backgroundColor: "#FE9023" }}
                  className={classes.button}
                >
                  Btn
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tariffs;
