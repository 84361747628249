import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";

const items = [
  {
    name: "Food and beverages",
    icon: "https://interneturok.ru/subject/no_background/math.svg",
    color: "#EB636D",
  },
  {
    name: "Metals",
    icon: "https://interneturok.ru/subject/no_background/subject-3.svg",
    color: "#EB636D",
  },
  {
    name: "Building materials",
    icon: "https://interneturok.ru/subject/no_background/subject-8.svg",
    color: "#2b8e7a",
  },
  {
    name: "Clothing. Shoes. Toys",
    icon: "https://interneturok.ru/subject/no_background/subject-2.svg",
    color: "#EB636D",
  },
  {
    name: "Electronics and electrical engineering",
    icon: "https://interneturok.ru/subject/no_background/russian.svg",
    color: "#4681b9",
  },
  {
    name: "Cars and car service ",
    icon: "https://interneturok.ru/subject/no_background/physics.svg",
    color: "#2b8e7a",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage:
      "url(https://interneturok.ru//static/img/abonement_kid.jpg)",
    width: "100%",
    minHeight: 500,
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: 60,
    [theme.breakpoints.down(1200)]: {
      minHeight: 300,
      backgroundPosition: "-30rem",
    },
    [theme.breakpoints.down(1000)]: {
      minHeight: 300,
      backgroundPosition: "-10rem",
    },
    [theme.breakpoints.down(700)]: {
      minHeight: 100,
      padding: 20,
      backgroundPosition: "-30rem",
    },
  },
  wrapper: {
    maxWidth: 1000,
    margin: "auto",
  },
  title: {
    fontSize: 30,
    fontWeight: 700,
    textTransform: "capitalize",
    marginBottom: 50,
    [theme.breakpoints.down(1000)]: {
      fontSize: 30,
      fontWeight: 500,
      textAlign: "center",
    },
    [theme.breakpoints.down(700)]: {
      marginTop: 40,
    },
  },
  bluetitle: {
    color: "#1062D8",
    padding: "0 15px",
  },
  Sectionsbox: {
    backgroundColor: "white",
    display: "block",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.14)",
    borderRadius: 12,
    position: "relative",
    padding: "40px 100px 50px 70px",
    [theme.breakpoints.down(1000)]: {
      padding: 20,
      paddingLeft: 40,
    },
    [theme.breakpoints.down(700)]: {
      top: 50,
    },
  },
  boxTitle: {
    borderBottom: "2px dashed rgba(196, 196, 196, 0.33)",
    paddingBottom: 25,
    fontSize: 25,
    fontWeight: 600,
    lineHeight: "35px",
    marginBottom: 30,
    [theme.breakpoints.down(1000)]: {
      marginBottom: 20,
      paddingBottom: 20,
      fontSize: 20,
      fontWeight: 400,
    },
  },
  box: {
    width: 800,
    [theme.breakpoints.down(1000)]: {
      width: 500,
    },
    [theme.breakpoints.down(700)]: {
      width: "100%",
    },
  },
  subjectName: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  icon: {
    width: 30,
    height: 30,
    [theme.breakpoints.down(1000)]: {
      width: 15,
      height: 15,
    },
  },
  subjectNametxt: {
    padding: "0 10px",
    fontWeight: 500,
    [theme.breakpoints.down(1000)]: {
      width: 15,
      height: 15,
      fontSize: 15,
    },
    [theme.breakpoints.down(700)]: {
      fontSize: 9,
    },
  },
}));
const HeadBanner = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.box}>
          <h1 className={classes.title}>
            <FormattedMessage id="header.Available" />
            <span className={classes.bluetitle}>
              <FormattedMessage id="header.Products" />
            </span>
          </h1>
          <div className={classes.Sectionsbox}>
            <h2 className={classes.boxTitle}>Sections :</h2>
            <div className="grid grid-cols-3 gap-4">
              {items.map((item, i) => {
                return (
                  <div key={i} className={classes.subjectName}>
                    <a
                      className="flex justify-center items-center"
                      href={`#${item.name}`}
                      target="_blank"
                    >
                      <span className={classes.icon}>
                        <img alt={item.name} src={item.icon} />
                      </span>
                      <span
                        style={{ color: item.color }}
                        className={classes.subjectNametxt}
                      >
                        <span>{item.name}</span>
                      </span>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadBanner;
