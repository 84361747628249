import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const list = ["1", "2", "3", "4", "5", "6", "7", "8"];

const useStyles = makeStyles((theme) => ({
  img: {
    height: 150,
    objectFit: "cover",
  },
  title: {
    fontSize: 12,
  },
  border: {
    width: 100,
    margin: "auto",
    borderBottom: "2px solid #D7EDFF",
  },
  text: {
    color: "#4681b9",
  },
  badge: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    width: 50,
    height: 50,
    borderRadius: "50%",
    position: "absolute",
    left: 10,
    top: 10,
  },
  box:{
    transition:"0.3s",
    top:0,
    position:"relative",
    cursor:"pointer",
    "&:hover":{
      top:-5,
      transition:"0.3s",
    }
  }
}));
const Materials = () => {
  const classes = useStyles();
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-8">
      {list.map((item, i) => (
        <div
          key={i}
          className={`max-w-sm rounded overflow-hidden shadow-lg bg-white ${classes.box}`}
        >
          <img
            className={`${classes.img} w-full`}
            src="https://interneturok.ru/img/dop_materials/5.jpg"
            alt="Sunset in the mountains"
          />
          <div className="px-6 py-4 text-center">
            <div className={`mb-2 pb-2 ${classes.title}`}>
              The Coldest Sunset
            </div>
            <div className={classes.border} />
            <p className={`pt-4 text-base ${classes.text}`}>
              Supplayer literature. Reading together
            </p>
          </div>
          <div className={classes.badge}>
            <img
              width={30}
              src="https://interneturok.ru/subject/no_background/subject-14.svg"
              alt="badge"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Materials;
