import * as Actions from "./requestLesson.action";

const initialState = {
  subject: null,
  level: "",
  place: "Online",
  postcode: "",
  lessonsfor: "-",
  often: "Regular",
  date: "Invalid Date",
  duration: "1:00",
  discription: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SUBJECT:
      return {
        ...state,
        subject: action.subject,
      };
    case Actions.LEVEL:
      return {
        ...state,
        level: action.level,
      };
    case Actions.PLACE:
      return {
        ...state,
        place: action.place,
      };
    case Actions.POSTCODE:
      return {
        ...state,
        postcode: action.postcode,
      };
    case Actions.LESSONSFOR:
      return {
        ...state,
        lessonsfor: action.lessonsfor,
      };
    case Actions.OFTEN:
      return {
        ...state,
        often: action.often,
      };
    case Actions.DATE:
      return {
        ...state,
        date: action.date,
      };
    case Actions.DURATION:
      return {
        ...state,
        duration: action.duration,
      };
    case Actions.DISCRIPTION:
      return {
        ...state,
        discription: action.discription,
      };
    default:
      return state;
  }
};
export default reducer;
