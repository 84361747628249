import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Title from "components/landing/Title";
import { Button } from "@material-ui/core";

const List = [
  {
    title: "Food and beverages",
    icon: "https://interneturok.ru/subject/no_background/math.svg",
    lock: true,
    number: 20,
    lessens: ["7", "8", "9", "10", "Last"],
  },
  {
    title: "Clothing. Shoes. Toys",
    icon: "https://interneturok.ru/subject/no_background/subject-2.svg",
    lock: false,
    number: 42,
    lessens: ["7", "8", "9", "10", "Last"],
  },
  {
    title: "Metals",
    icon: "https://interneturok.ru/subject/no_background/subject-3.svg",
    lock: true,
    number: 17,
    lessens: ["7", "8", "9", "10", "Last"],
  },
  {
    title: "Building materials",
    icon: "https://interneturok.ru/subject/no_background/physics.svg",
    lock: false,
    number: 70,
    lessens: ["7", "8", "9", "10", "Last"],
  },
  {
    title: "Health and beauty",
    icon: "https://interneturok.ru/subject/no_background/subject-8.svg",
    lock: false,
    number: 42,
    lessens: ["7", "8", "9", "10", "Last"],
  },
  {
    title: "Cars and car service",
    icon: "https://interneturok.ru/subject/no_background/subject-9.svg",
    lock: false,
    number: 137,
    lessens: ["7", "8", "9", "10", "Last"],
  },
  {
    title: "Sport. Tourism. Stay",
    icon: "https://interneturok.ru/subject/no_background/russian.svg",
    lock: false,
    number: 79,
    lessens: ["7", "8", "9", "10", "Last"],
  },
  {
    title: "Chemicals and Fuel",
    icon: "https://interneturok.ru/subject/no_background/subject-14.svg",
    lock: false,
    number: 42,
    lessens: ["7", "8", "9", "10", "Last"],
  },
  {
    title: "Industrial equipment  ",
    icon: "https://interneturok.ru/subject/no_background/subject-17.svg",
    lock: true,
    number: 137,
    lessens: ["7", "8", "9", "10", "Last"],
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e5f3fe",
    marginTop: 20,
  },
  wrapper: {
    maxWidth: 1150,
    margin: "auto",
  },
  item: {
    justifyContent: "space-between",
    height: 60,
    borderRadius: 10,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor: "white",
    alignItems: "center",
    margin: "5px 5px ",
    display: "flex",
    padding: 20,
    borderBottom: "2px solid rgba(188, 218, 243, 0.32)",
    top:0,
    position:"relative",
    cursor:"pointer",
    transition:"0.3s",
    "&:hover":{
      top:-5,
      transition:"0.3s",
    }
  },
  libraryNumber: {
    fontSize: 35,
    fontWeight: 500,
    color: "rgb(16, 97, 216)",
  },
  libraryBox: {
    width: "100%",
    minHeight: 300,
    borderRadius: 12,
  },
  lock: {
    width: 30,
    height: 30,
    margin: "0 5px",
    position: "relative",
    display: "inline-block",
    background: "#D5E9F9",
    borderRadius: "50%",
    verticalAlign: -8,
    marginLeft: 10,
    cursor: "pointer",
  },
  lockIcon: {
    backgroundImage:
      "url(https://interneturok.ru//static/img/subject-block.svg)",
    backgroundRepeat: "no-repeat",
    width: 15,
    backgroundSize: 15,
    height: 22,
    margin: "auto",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  lessens: {
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.14)",
    color: "black",
    backgroundColor: "white",
    padding: "13px 12px",
    borderRadius: 5,
    margin: "0 2px",
    transition:"0.3s",
  },
}));
const LessensItems = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div
          style={{ backgroundColor: props.bg, boxShadow: props.shadow }}
          className={classes.libraryBox}
        >
          <div className="block mb-4 flex-wrap md:flex ">
            <div
              className={`flex flex-col w-full md:w-1/3 border-none md:border-dashed ${classes.list1}`}
            >
              {List.slice(0, 3).map((item, i) => {
                return (
                  <div className={classes.item} key={i}>
                    <div className={`flex items-center`}>
                      <div className="flex items-center font-semibold ">
                        <img src={item.icon} height={25} alt="icon" />
                        <span style={{ color: "#EB636D" }} className="px-2">
                          {item.title}
                        </span>
                      </div>
                    </div>
                    {item.lock && (
                      <span className={classes.lock}>
                        <span className={classes.lockIcon}></span>
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
            <div
              className={`flex flex-col w-full md:w-1/3 border-none md:border-dashed  ${classes.list1}`}
            >
              {List.slice(3, 6).map((item, i) => {
                return (
                  <div className={classes.item} key={i}>
                    <div
                      style={{ justifyContent: props.justify }}
                      className={`flex items-center`}
                    >
                      <div className="flex items-center font-semibold ">
                        <img src={item.icon} height={25} alt="icon" />
                        <span style={{ color: "#2b8e7a" }} className="px-2">
                          {item.title}
                        </span>
                      </div>
                    </div>
                    {item.lock && (
                      <span className={classes.lock}>
                        <span className={classes.lockIcon}></span>
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
            <div
              className={`flex flex-col w-full md:w-1/3 border-none md:border-dashed  px-4`}
            >
              {List.slice(6, 9).map((item, i) => {
                return (
                  <div className={classes.item} key={i}>
                    <div
                      style={{ justifyContent: props.justify }}
                      className={`flex  items-center`}
                    >
                      <div className="flex items-center font-semibold ">
                        <img src={item.icon} height={25} alt="icon" />
                        <span style={{ color: "#4681b9" }} className="px-2">
                          {item.title}
                        </span>
                      </div>
                      <div className="flex items-center"></div>
                    </div>
                    {item.lock && (
                      <span className={classes.lock}>
                        <span className={classes.lockIcon}></span>
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessensItems;
