import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Title from "./Title";
import { FormattedMessage, injectIntl } from "react-intl";

const Learners = [
  {
    title: "Amazing tutor",
    slogan:
      "Amazing tutor, I've learned a lot from him during our classes, I highly recommend.",
    image:
      "https://content.tutor.id/6224/profilePictures/305563416975960_20190201T163257.png",
    name: "Natali G.",
    subject: "English",
  },
  {
    title: "Outstanding professional",
    slogan:
      "I was a student of teacher Lia, and she is an outstanding professional.",
    image:
      "https://content.tutor.id/4829/profilePictures/1591696924249489_20180120T132357.png",
    name: "Maria Carolina Z.",
    subject: "Conversational English",
  },
];

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".react-multi-carousel-dot button": {
      width: "15px !important",
      height: "15px !important",
      borderColor: "rgb(163, 167, 191, 0.5) !important",
    },
    ".react-multi-carousel-dot--active button": {
      backgroundColor: "white !important",
      borderColor: "rgb(163, 167, 191) !important",
    },
  },
  root: {
    backgroundColor: "white",
    margin: "auto",
    padding: "6em 0",
  },
  wrapper: {
    display: "flex",
    maxWidth: 1000,
    margin: "auto",
  },
  card: {
    backgroundColor: "rgb(245, 246, 250)",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px",
    minHeight: 200,
    width: "100%",
    margin: "70px auto",
    padding: 36,
    cursor: "pointer",
  },
  cardTitle: {
    fontSize: 23,
    fontWeight: 400,
    [theme.breakpoints.down(600)]: {
      fontSize: 25,
    },
  },
  cardTxt: {
    margin: "1em 0",
    fontSize: 12,
    lineHeight:1,
    fontWeight: "lighter",
    color: "black",
    [theme.breakpoints.down(600)]: {
      fontSize: 15,
    },
  },
  cardImg: {
    width: "25%",
    height: 100,
    borderRadius: 6,
    [theme.breakpoints.down(600)]: {
      width: "50%",
    },
  },
  subject: {
    fontSize: 17,
    fontWeight:400,
    display: "flex",
    alignItems: "center",
    lineHeight: "2em",
    [theme.breakpoints.down(600)]: {
      fontSize: 15,
    },
  },
  logo: {
    width: 110,
    height: 110,
    margin: "0 10px",
    [theme.breakpoints.down(600)]: {
      width: 90,
      height: 90,
    },
  },
  logoTxt: {
    fontSize: 17,
    lineHeight: "1.4em",
    padding: "0 30px",
    textAlign: "justify",
    [theme.breakpoints.down(600)]: {
      fontSize: 14,
    },
    "&:before": {
      content: "'“'",
      padding: "0px 10px 100px 0px",
      margin: "7px 0px 0px",
      fontSize: 60,
      fontFamily: "Times New Roman, Times, serif",
      fontWeight: 700,
      float: "left",
      color: "rgb(186, 187, 191)",
    },
    "&:after": {
      content: "'”'",
      padding: "0px 10px 100px 0px",
      margin: "7px 0px 0px",
      fontSize: 60,
      fontFamily: "Times New Roman, Times, serif",
      fontWeight: 700,
      float: "right",
      color: "rgb(186, 187, 191)",
    },
  },
  carousel: {
    direction: "initial !important",
    position: "relative",
  },
}));
const Learner = ({ intl }) => {
  const classes = useStyles();
  useEffect(() => {
    window.scroll(0, 0);
  });
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  const [width, setWidth] = useState(window.innerWidth);

  return (
    <div data-aos="fade-up" className={classes.root}>
      <Title
        title={intl.formatMessage({
          id: "Tutors.app.learners.title",
          defaultMessage: "Over 10,000 learners are happy to tell",
        })}
      />
      <div className={classes.carousel}>
        {width < 900 ? (
          <Carousel
            arrows={false}
            centerMode={false}
            autoPlay
            autoPlaySpeed={4000}
            draggable
            infinite
            itemClass="px-6"
            keyBoardControl
            minimumTouchDrag={80}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 2,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 1024,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
            }}
            showDots
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {Learners.map((item, i) => (
              <div key={i} className={classes.card}>
                <h3 className={classes.cardTitle}>{item.title}</h3>
                <p className={classes.cardTxt}>{item.slogan}</p>
                <div className="flex">
                  <img
                    className={classes.cardImg}
                    alt="profile"
                    src={item.image}
                  />
                  <h6 className={`px-6 ${classes.subject}`}>
                    <FormattedMessage
                      id="Tutors.app.learners.Learner"
                      defaultMessage="Learner"
                    />
                    : {item.name} <br />
                    <FormattedMessage
                      id="Tutors.app.learners.subject"
                      defaultMessage="Subject"
                    />
                    : {item.subject}
                  </h6>
                </div>
              </div>
            ))}
          </Carousel>
        ) : (
          <Carousel
            arrows={false}
            centerMode={true}
            draggable
            infinite
            itemClass="px-6"
            keyBoardControl
            minimumTouchDrag={80}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 2,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 1024,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
            }}
            showDots
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {Learners.map((item, i) => (
              <div key={i} className={classes.card}>
                <h3 className={classes.cardTitle}>{item.title}</h3>
                <p className={classes.cardTxt}>{item.slogan}</p>
                <div className="flex">
                  <img
                    className={classes.cardImg}
                    alt="profile"
                    src={item.image}
                  />
                  <h6 className={`px-6 ${classes.subject}`}>
                    <FormattedMessage
                      id="Tutors.app.learners.Learner"
                      defaultMessage="Learner"
                    />
                    : {item.name} <br />
                    <FormattedMessage
                      id="Tutors.app.learners.subject"
                      defaultMessage="subject"
                    />
                    : {item.subject}
                  </h6>
                </div>
              </div>
            ))}
          </Carousel>
        )}
      </div>
      <div style={{ height: 170 }} className={`${classes.wrapper} pt-8`}>
        <img
          data-aos="fade-up"
          className={classes.logo}
          src="https://tutor.id/static/svg/coat-of-arms-of-estonia.svg"
        />
        <p data-aos="fade-up" className={classes.logoTxt}>
          <FormattedMessage id="Tutors.app.learners.estonian" />
        </p>
      </div>
    </div>
  );
};

export default injectIntl(Learner);
