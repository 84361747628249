import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "./Card";
import { Button } from "@material-ui/core";
import { WhereAction } from "store/searchBySubjects";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1200,
    margin: "50px auto",
  },
  card: {
    width: 280,
    maxHeight: 150,
    padding: "10px 0",
    backgroundColor: "white",
    boxShadow: "0 2px 4px rgba(115, 166, 227, 0.4)",
    margin: 10,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down(900)]: {
      width: 100,
    },
  },
  title: {
    color: "#232f49",
    fontSize: "1.3rem",
    fontWeight: 700,
    [theme.breakpoints.down(900)]: {
      textAlign: "center",
    },
  },
  skip: {
    backgroundColor: "#e2e2e2",
    color: "#545454",
    width: 90,
    height: 30,
  },
}));
const Where = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const Category = useSelector((state) => state.searchC);
  const WhereHandler = (item) => {
    dispatch(WhereAction(item));
  };
  const skipHandler = (item) => {
    dispatch(WhereAction(" "));
  };
  return (
    <div data-aos="fade-up" className={classes.root}>
      <div className="justify-between flex ">
        <h2 className={classes.title}>Where would you like your lessons?</h2>
        <Button onClick={skipHandler} className={classes.skip}>Skip</Button>
      </div>
      <div className="flex flex-wrap w-full ">
        {!Category.data.data
          ? null
          : Category.data.data.map((item, i) => {
              return (
                <div
                  onClick={() => WhereHandler(item.title)}
                  key={i}
                  className={classes.card}
                >
                  <Card title={item.title} />
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default Where;
