import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardSlider from "components/landing/CardSlider";
import Title from "components/landing/Title";
import OpportunitiesCard from "./OpportunitiesCard";
import { Button } from "@material-ui/core";

const Lists = [
  {
    title: "Creating your own substitute for witchcraft",
    icon:
      "https://interneturok.ru/_default_upload_bucket/opportunities-1_1.svg",
  },
  {
    title: "Ability to add spells to Selected",
    icon:
      "https://interneturok.ru/_default_upload_bucket/opportunities-2_1.svg",
  },
  {
    title: "Watching video tutorials",
    icon:
      "https://interneturok.ru/_default_upload_bucket/opportunities-3_1.svg",
  },
  {
    title: "Interactive video elements",
    icon:
      "https://interneturok.ru/_default_upload_bucket/opportunities-4_1.svg",
  },
  {
    title: "Interactive video elements",
    icon:
      "https://interneturok.ru/_default_upload_bucket/opportunities-5_1.svg",
  },
  {
    title: "Ability to add spells to Selected",
    icon:
      "https://interneturok.ru/_default_upload_bucket/opportunities-6_1.svg",
  },
  {
    title: "Ability to add spells to Selected",
    icon:
      "https://interneturok.ru/_default_upload_bucket/opportunities-7_1.svg",
  },
];

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: 1000,
    margin: "auto",
    paddingTop: "4em",
    [theme.breakpoints.down(1000)]: {
      display: "block !important",
    },
  },
  btnBox: {
    width: 420,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down(1000)]: {
      margin: "20px auto",
      width: "auto",
    },
  },
  payBtn: {
    backgroundColor: "#FE9023",
    color: "white",
    padding: "12px 0",
    transition: "0.3s",
    height: 60,
    fontSize:11,
    padding: "20px 50px",
    borderRadius: 70,
    "&:hover": {
      backgroundColor: "#FE9023",
      transition: "0.3s",
      height: 62,
      padding: "20px 60px",
    },
  },
  askbtn: {
    backgroundColor: "white",
    color: "black",
    height: 60,
    fontSize:11,
    padding: "20px 50px",
    borderRadius: 70,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)",
    [theme.breakpoints.down(1000)]: {
      margin: "20px auto",
      display: "block",
    },
  },
  text: {
    fontSize: 12,
    fontWeight: 700,
    padding: "0 10px",
    [theme.breakpoints.down(1000)]: {
      textAlign: "center",
    },
  },
}));
const Slider = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.wrapper}>
        <Title black="header.Opportunities" blue="header.subscription" />
      </div>
      <CardSlider
        itemsToShow={5}
        data={Lists.map((item, i) => {
          return (
            <div key={i}>
              <OpportunitiesCard text={item.title} image={item.icon} />
            </div>
          );
        })}
      />
      <div
        style={{ borderBottom: "2px dashed rgba(196, 196, 196, .33)" }}
        className={`flex pb-16 ${classes.wrapper}`}
      >
        <div className={classes.btnBox}>
          <Button className={classes.payBtn}>pay for a subscription</Button>
        </div>
        <Button className={classes.askbtn}>Frequently asked questions</Button>
        <div className="flex items-center px-8 justify-center">
          <img
            width={30}
            src="https://interneturok.ru//static/img/abonement-info.svg"
            alt="infoicon"
          />
          <p className={classes.text}>
            All other sections and services are freely available, as are lessons
            <span style={{ color: "#1062D8" }}>
              with the "Free Access" marker
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Slider;
