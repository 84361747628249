import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    position:"relative",
    zIndex:0,
    backgroundColor: "white",
    color: "#232f49",
    minHeight: 300,
    width: "65%",
    marginTop: 18,
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 5px 10px rgba(0, 97, 161, 0.55)",
    },
    [theme.breakpoints.down(1200)]: {
      width:"100%",
    },
  },
  title: {
    fontSize: 35,
    fontWeight: 900,
    margin: "0 20px",
    [theme.breakpoints.down(675)]: {
      fontSize:20,
      margin:"0 2px"
    },
  },
  icon: {
    color: "#4cbbc2",
    fontSize: 40,
    [theme.breakpoints.down(675)]: {
      fontSize:20
    },
  },
  subTitle: {
    fontSize: 25,
    fontWeight: 800,
    marginBottom: "1rem",
    [theme.breakpoints.down(675)]: {
      fontSize:15
    },
  },
  txt: {
    fontSize: 17,
    lineHeight: 1.4,
    color: "rgb(88, 88, 88)",
    [theme.breakpoints.down(675)]: {
      fontSize:14
    },
  },
}));

const Card = (props) => {
  const classes = useStyles();
  return (
    <div
      id={props.link}
      style={{ maxWidth: 1200, margin: "auto" }}
      className="flex flex-row-reverse"
    >
      <div className={` rounded overflow-hidden ${classes.card}`}>
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              {props.icon}
              <h6 className={classes.title}>{props.title}</h6>
            </div>
            <span>{props.star}</span>
          </div>
          <div className={classes.subTitle}>{props.subtitle}</div>
          <div className={classes.txt}>{props.body}</div>
        </div>
      </div>
    </div>
  );
};

export default Card;
