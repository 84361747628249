import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    minHeight: 200,
    backgroundColor: "white",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.14)",
    padding: "25px 255px 45px 30px",
    borderRadius: 12,
    alignItems: "center",
    display: "flex",
    marginBottom: 20,
    [theme.breakpoints.down(1000)]: {
      flexDirection: "column",
      padding: 50,
      textAlign: "center",
    },
  },
}));
const CardService = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.card}>
      <img width={150} className="mx-10" alt="icon" src={props.image} />
      <div className="w-full">
        <h3 className="font-medium text-2xl mt-4">{props.title}</h3>
        <p className="text-sm mt-4 text-gray-700">{props.text}</p>
      </div>
    </div>
  );
};

export default CardService;
