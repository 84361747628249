// application
import enMessages from './messages/en';
import ruMessages from './messages/ru';
import faMessages from './messages/ar';

export default {
	ar: faMessages,
    en: enMessages,
	ru: ruMessages,
    
};
