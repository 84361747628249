import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    [theme.breakpoints.down(1100)]: {
      display: "block !important",
    },
  },
  bgImg: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "90vh",
    padding: 150,
    position: "relative",
    [theme.breakpoints.down(1250)]: {
      padding: 50,
      height: 550,
    },
  },
  title: {
    fontSize: 50,
    fontWeight: 500,
    textShadow: "6px 5px 36px rgba(255, 255, 255, 0.43)",
    letterSpacing: 2,
    textTransform: "capitalize",
    [theme.breakpoints.down(1250)]: {
      fontSize: 30,
      textAlign:"center",
    },
  },
  btn: {
    width: 100,
    height: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(1250)]: {
      width: 50,
      height: 50,
    },
  },
  arrow: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    bottom: 100,
    left: 100,
  },
  arrowBtn: {
    width: 90,
    height: 90,
    borderRadius: "50%",
    padding:24,
    transition: "0.3s",
    "&:hover": {
      transition: "0.3s",
      width: 100,
      height: 100,
    },
    [theme.breakpoints.down(1250)]: {
      width: 50,
      height: 50,
      "&:hover": {
        transition: "0.3s",
        width: 50,
        height: 50,
      },
    },
  },
}));

const Banner = (props) => {
  const classes = useStyles();
  return (
    <div
      style={{ backgroundImage: `url(${props.bgimg})` }}
      className={`w-full ${classes.bgImg}`}
    >
      <h2 className={classes.title}>
        {props.title} <br />
        <span style={{ color: props.color }}>{props.colorfultitle}</span>
        <br />
        {props.title2}
      </h2>
      {props.text}
      <div className={classes.arrow}>
        <div className={classes.btn}>
          <Button
            onClick={props.click}
            style={{ backgroundColor: props.color }}
            className={classes.arrowBtn}
          >
            <img alt="arrowbtn" src={props.arrowbtn} />
          </Button>
        </div>
        <p className="mx-6 sm:text-xl font-semibold">More details</p>
      </div>
    </div>
  );
};

const HeadBanner = () => {
  const classes = useStyles();
  const scrollToTitle = () => {
    window.scrollTo({
      top: 900,
      behavior: "smooth",
    });
  };
  const returnHome=()=>{
    window.open("http://localhost:3000/", "_blank")
  }
  return (
    <div className={`flex ${classes.root}`}>
      <Banner
        click={()=>scrollToTitle()}
        bgimg={"https://interneturok.ru/img/2_l_1920_1.jpg"}
        title={"Library of video"}
        color="#1062D8"
        colorfultitle={"tutorials"}
        title2={"school curriculum"}
        text={
          <p className="text-gray-700 text-lg mt-16">
            Videos, notes, tests, simulators.
            <br />
            All major subjects and classes.
          </p>
        }
        arrowbtn={"https://interneturok.ru//static/img/halfblock-ico-1.svg"}
      />
      <Banner
        click={()=>returnHome()}
        bgimg={"https://interneturok.ru/img/2_r_1920_1.jpg"}
        title={"Home school"}
        color="#FE9023"
        colorfultitle={"«Internet Lesson»"}
        text={
          <p className="text-gray-700 text-lg mt-16">
            Complete secondary education Complete secondary education
            <br />
            All major subjects and classes.
          </p>
        }
        arrowbtn={"https://interneturok.ru//static/img/halfblock-ico-2.svg"}
      />
    </div>
  );
};

export default HeadBanner;
