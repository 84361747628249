import axios from "axios";

export const LEARNERS_LOADING = "GET_LEARNERS";
export const LEARNERS_SUCCESS = "LEARNERS_SUCCESS";
export const LEARNERS_FAILED = "LEARNERS_FAILED";

export const getLearnersLoading = () => {
  return {
    type: LEARNERS_LOADING,
  };
};

export const getLearnersFailure = (error) => {
  return {
    type: LEARNERS_FAILED,
    error,
  };
};
export const getSuccess = (data) => {
  return {
    type: LEARNERS_SUCCESS,
    data,
  };
};

export function getLearners(options, language="en", city="all-country") {
  let searchText = "";
  let category = "";
  let subject = "";
  let seed = "";
  let price = [0, 5000];
  let limit = 10;
  let rate = [0, 5];
  let skip = 0;
  if (options) {
    searchText = options.searchText ? options.searchText : "";
    category = options.category ? options.category : "";
    subject = options.subject ? options.subject : "";
    seed = options.seed ? options.seed : "";
    price = options.price ? options.price : [0, 12500000];
    limit = options.limit ? options.limit : 10;
    rate = options.rate ? options.rate : [0, 5];
    skip = options.skip ? options.skip : 0;
  }
  const request = axios.get(
    `https://panel.eurasiaeco.ru/eda/v1/tutors-list?cityLocation=${city}&SSIPD=5.63.13.164&lang=en&limit=${limit}&skip=${
      skip * limit
    }&rate=${rate[0]},${rate[1]}&price=${price[0]},${
      price[1]
    }&subject=${subject}&category=${category}&searchtxt=${searchText}&seed=${seed}`
  );
  return (dispatch) => {
    dispatch(getLearnersLoading());
    request
      .then((response) => {
        dispatch(getSuccess(response.data))
      })
      .catch((err) => {
        dispatch(getLearnersFailure());
      });
  };
}
