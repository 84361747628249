import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from "react-intl";

import { TextField } from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1200,
    margin: "50px auto",
    padding: 10,
    [theme.breakpoints.down(1250)]: {
      textAlign: "center",
    },
  },
  input: {
    marginTop: 20,
    width: "100%",
    backgroundColor: "white",
    display: "block",
    width: "100%",
    height: "auto",
    borderRadius: 4,
    "&::placeholder": {
      color: "black",
    },
  },
}));

const SearchFiled = (props) => {
  const classes = useStyles();
  const SubjectSearch = useSelector((state) => state.SubjectSearch);
  return (
    <div className={classes.root}>
      <p className="text-gray-700">
        <FormattedMessage
          id="Search.app.search"
          defaultMessage="search a subject"
        />
      </p>
      <TextField
        fullWidth
        className={classes.input}
        id="outlined-basic"
        label="Subject"
        value={SubjectSearch.subjects}
        variant="outlined"
      />
    </div>
  );
};

export default injectIntl(SearchFiled);
