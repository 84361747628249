import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Libraries from "components/abonement/Libraries";
import LessensItems from "./LessensItems";
import LessenClasses from "./LessenClasses";
import Materials from "./Materials";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e5f3fe",
    padding: 50,
    [theme.breakpoints.down(1200)]:{
      padding:10
    }
  },
  wrapper: {
    maxWidth: 1150,
    margin: "auto",
  },
  border: {
    borderBottom: "2px dashed #BCDAF3",
  },
  title: {
    textAlign: "center",
    fontSize: 25,
    lineHeight: 1.5,
    fontWeight: 700,
    marginBottom: 50,
  },
  buttonSubject: {
    padding: "10px 30px",
    height: 45,
    backgroundColor: "white",
    borderRadius: 100,
    margin: 8,
    color: "black",
    textTransform: "upercase",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.14)",
    [theme.breakpoints.down(700)]:{
      margin:"10px auto",
      width:260
    }
  },
  input: {
    width: 450,
    height: 47,
    fontSize: 16,
    color: "#3876B0",
    borderRadius: 100,
    backgroundColor: "#CEE7FB",
    padding: "0 20px",
    "&::placeholder": {
      color: "#3876B0",
    },
    [theme.breakpoints.down(700)]:{
      margin:"auto",
      width:260,
      "&::placeholder": {
        opacity:0,
      },
    }
  },
  active: {
    padding: "10px 30px",
    height: 45,
    borderRadius: 100,
    margin: 8,
    textTransform: "upercase",
    fontSize: 16,
    color: "#1062D8",
    border: "2px solid #1062D8",
    borderRadius: 100,
    backgroundColor: "transparent",
    [theme.breakpoints.down(700)]:{
      margin:"10px auto",
      width:260
    }
  },
  registerBtn: {
    backgroundColor: "#FE9023",
    color: "white",
    padding: "12px 0",
    transition: "0.3s",
    height: 60,
    width: 300,
    borderRadius: 70,
    "&:hover": {
      backgroundColor: "#FE9023",
      transition: "0.3s",
      width: 320,
    },
  },
  subscriptionBtn: {
    backgroundColor: "#1061d8",
    color: "white",
    padding: "12px 0",
    transition: "0.3s",
    height: 60,
    width: 300,
    borderRadius: 70,
    "&:hover": {
      backgroundColor: "#1061d8",
      transition: "0.3s",
      width: 320,
    },
    [theme.breakpoints.down(700)]:{
      margin:"auto",
      width:260
    }
  },
  btn: {
    width: 330,
    [theme.breakpoints.down(1000)]: {
      margin: "10px auto !important",
      width: "auto",
    },
  },
}));

const Tutorials = () => {
  const classes = useStyles();
  const [Items, setItems] = useState(0);
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <h3 className={classes.title}>
          Library of video lessons on the school curriculum The largest <br />
          <span className="font-normal">
            collection of lessons from the best teachers in Runet
          </span>
        </h3>
        <div
          className={`flex-col md:flex-row  flex items-center justify-between pb-8 ${classes.border}`}
        >
          <div className="flex flex-col md:flex-row">
            <Button
              onClick={() => setItems(0)}
              className={Items === 0 ? classes.active : classes.buttonSubject}
            >
              BCE
            </Button>
            <Button
              onClick={() => setItems(1)}
              className={Items === 1 ? classes.active : classes.buttonSubject}
            >
              Items
            </Button>
            <Button
              onClick={() => setItems(2)}
              className={Items === 2 ? classes.active : classes.buttonSubject}
            >
              Classes
            </Button>
            <div className={`flex ${classes.input}`}>
              <input
                placeholder="Search by video urokam"
                value=""
                onChange={() => console.log("a")}
                className={`w-full p-0 ${classes.input}`}
              />
              <img
                alt="search"
                src="https://interneturok.ru//static/img/search_ico.svg"
              />
            </div>
          </div>
          <Button
            onClick={() => setItems(3)}
            className={Items === 3 ? classes.active : classes.buttonSubject}
          >
            Additional materials
          </Button>
        </div>
        {Items === 0 && <Libraries />}
        {Items === 1 && <LessensItems />}
        {Items === 2 && <LessenClasses />}
        {Items === 3 && <Materials />}
      </div>
      <div className="block md:flex justify-center my-8">
        <div className={`flex justify-center ${classes.btn}`}>
          <Button className={classes.registerBtn}>Pay register</Button>
        </div>
        <div className={`flex justify-center ${classes.btn}`}>
          <Button className={classes.subscriptionBtn}>
            Pay for subscription
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Tutorials;
