import axios from "axios";

export const CATEGORY_LOADING = "CATEGORY_LOADING";
export const CATEGORY_SUCCESS = "CATEGORY_SUCCESS";
export const CATEGORY_FAILED = "CATEGORY_FAILED";

export const getCategoryLoading = () => {
  return {
    type: CATEGORY_LOADING,
  };
};

export const getCategoryFailure = (error) => {
  return {
    type: CATEGORY_FAILED,
    error,
  };
};
export const getCategorySuccess = (data) => {
  return {
    type: CATEGORY_SUCCESS,
    data,
  };
};

export function getCategoty() {
  const request = axios.get(
    `https://panel.eurasiaeco.ru/eda/v1/tutors-Category?cityLocation=all-country&SSIPD=5.63.13.165&lang=en&limit=40&skip=0`
  );
  return (dispatch) => {
    dispatch(getCategoryLoading());
    request
      .then((response) => dispatch(getCategorySuccess(response.data)))
      .catch((err) => {
        dispatch(getCategoryFailure());
      });
  };
}
