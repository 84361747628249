import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ChooseLearn from "./ChooseLearn";
import WhereLearn from "./WhereLearn";
import { Button } from "@material-ui/core";
import LessonsFor from "./LessonsFor";
import Often from "./Often";
import TimeOfLesson from "./TimeOfLesson";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: "73%",
    [theme.breakpoints.down(1280)]: {
      maxWidth: "100%",
      marginTop: 20,
    },
  },
  titleIcon: {
    color: "#4cbcc2",
    width: 45,
    [theme.breakpoints.down(1050)]: {
      width: 30,
    },
  },
  title: {
    color: "#232f49 !important",
    fontSize: "2.5rem",
    fontWeight: 800,
    margin: "0 10px",
    [theme.breakpoints.down(1050)]: {
      fontSize: "1.4rem",
    },
  },
  profileimg: {
    display: "none",
    [theme.breakpoints.down(1050)]: {
      display: "block",
      width: 100,
      position: "relative",
      top: -30,
      margin: "0 10px",
      height: 100,
      borderRadius: "50%",
    },
  },
  continue: {
    width: 230,
    height: 40,
    backgroundColor: "#d50951",
    borderRadius: 4,
    color: "white",
    marginTop: 20,
    fontWeight: 700,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#ba0037",
    },
  },
  back: {
    width: 230,
    height: 40,
    backgroundColor: "#e2e2e2",
    borderRadius: 4,
    color: "#545454",
    marginTop: 20,
    fontWeight: 700,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#b6b6b6",
    },
  },
  error: {
    color: "#ba0037",
    fontSize: 15,
    marginTop: 20,
    fontWeight: 700,
    textTransform: "capitalize",
    [theme.breakpoints.down(1050)]: {
      textAlign: "center",
    },
  },
}));

const Request = () => {
  const classes = useStyles();
  const [nextStep, setNextStep] = useState(0);
  const [error, setError] = useState(false);
  const Detail = useSelector((state) => state.Detail);
  const ReqLesson = useSelector((state) => state.ReqLesson);
  const nextHandler = (i) => {
    if (ReqLesson.subject !== null) {
      setNextStep(i);
      setError(false);
    } else {
      setError(true);
    }
  };
  {
    console.log(ReqLesson.subject);
  }
  return (
    <div className="flex justify-center">
      <div className={classes.wrapper}>
        <div className="flex items-center">
          <svg
            data-v-878e767e=""
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="paper-plane"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className={classes.titleIcon}
          >
            <path
              data-v-878e767e=""
              fill="currentColor"
              d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
            ></path>
          </svg>
          <h1 className={classes.title}>Request a lesson </h1>
        </div>
        <div className="flex relative justify-between">
          <p className="text-gray-700 text-base sm:text-xl mt-4">
            Tell Sylvana what you would like to learn...
          </p>
          {!Detail.data.data ? null : (
            <img
              className={classes.profileimg}
              src={`http://superadmin.nandwsouk.com/admin/${Detail.data.data.masterImage}`}
            />
          )}
        </div>
        {nextStep === 0 && (
          <>
            <ChooseLearn />
            <WhereLearn />
          </>
        )}
        {nextStep === 1 && (
          <>
            <LessonsFor />
            <Often />
            <TimeOfLesson />
          </>
        )}
        <div className="flex justify-center md:justify-between">
          {nextStep === 1 && (
            <Button onClick={() => nextHandler(0)} className={classes.back}>
              back
            </Button>
          )}
          <Button onClick={() => nextHandler(1)} className={classes.continue}>
            continue
          </Button>
        </div>
        {error && <p className={classes.error}>fill in the blanks</p>}
      </div>
    </div>
  );
};

export default Request;
