import axios from "axios";

export const SUBJECT_LOADING = "SUBJECT_LOADING";
export const SUBJECT_SUCCESS = "SUBJECT_SUCCESS";
export const SUBJECT_FAILED = "SUBJECT_FAILED";

export const getSubjectLoading = () => {
  return {
    type: SUBJECT_LOADING,
  };
};

export const getSubjectFailure = (error) => {
  return {
    type: SUBJECT_FAILED,
    error,
  };
};
export const getSuccess = (data) => {
  return {
    type: SUBJECT_SUCCESS,
    data,
  };
};

export function getSubjectSuccess() {
  const request = axios.get(
    `https://panel.eurasiaeco.ru/eda/v1/tutors-subject?cityLocation=32.456456,56.6456456&SSIPD=5.63.13.165&lang=en&limit=40&skip=0`
  );
  return (dispatch) => {
    dispatch(getSubjectLoading());
    request
      .then((response) => dispatch(getSuccess(response.data)))
      .catch((err) => {
        dispatch(getSubjectFailure());
      });
  };
}
