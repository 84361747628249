import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Profile from "./Profile";
import ProfileCard from "./ProfileCard";
import ImgPriceBox from "./ImgPriceBox";

const useStyles = makeStyles((theme) => ({
  header: {
    width: "100%",
    height: 280,
    transition: "0.4s",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: "#25314b",
    position: "fixed",
    zIndex: 1,
    backgroundImage:
      "url(https://portfoman.ir/static/images/portfoTeam/mohammad-maddahi.pngmainbgPaya.jpg)",
    "&:before": {
      content: "''",
      backgroundColor: "#232f4983",
      width: "100%",
      height: "100%",
      display: "block",
    },
    [theme.breakpoints.down(1200)]: {
      display: "none",
    },
  },
  headeritems: {
    position: "fixed",
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
    maxWidth: 1150,
    [theme.breakpoints.down(1200)]: {
      position: "relative",
    },
  },
  root: {
    maxWidth: 1150,
    margin: "auto",
    zIndex: 1,
    position: "relative",
    color: "white",
    padding: 30,
    [theme.breakpoints.down(1200)]: {
      display: "block",
      padding: 0,
    },
  },
}));

const HeadBanner = () => {
  const classes = useStyles();
  const [visible, setvisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", headerHeight);
    document.addEventListener("scroll", function (e) {
      toggleVisibility();
    });
    return function clean() {
      window.removeEventListener("scroll", headerHeight);
    };
  }, []);

  const toggleVisibility = () => {
    if (window.pageYOffset > 2700) {
      setvisible(true);
    } else {
      setvisible(false);
    }
  };
  const headerHeight = () => {
    if (window.pageYOffset < 50) {
      document.getElementsByClassName(classes.header)[0].style.height = "280px";
      document.getElementsByClassName(classes.header)[0].style.backgroundImage =
        "url(https://payapars.com/website/content/uploads/2018/12/company.jpg)";
    } else {
      document.getElementsByClassName(classes.header)[0].style.height = "180px";
      document.getElementsByClassName(classes.header)[0].style.backgroundImage =
        "none";
    }
  };
  return (
    <>
      <div className={classes.header} />
      <div className={classes.root}>
        <div className={classes.headeritems}>
          <Profile />
          {visible ? <ImgPriceBox /> : <ProfileCard />}
        </div>
      </div>
    </>
  );
};

export default HeadBanner;
