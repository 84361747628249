import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    fontSize: 30,
    fontWeight: 600,
    marginBottom: "0.5em",
    lineHeight:1.5,
    [theme.breakpoints.down(600)]: {
      fontSize:30
    },
  },
}));

const Title = (props) => {
  const classes = useStyles();
  return <h2 className={classes.title}>{props.title}</h2>;
};

export default Title;
