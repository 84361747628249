import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "transparent",
    display: "flex",
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: "0 20px",
  },
  check: {
    backgroundColor: "white",
    borderRadius: "50%",
    position: "absolute",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    width: 40,
    height: 40,
    left: 0,
    right: 100,
    top: 0,
    bottom: 25,
  },
}));
const Missing = () => {
  const classes = useStyles();
  return (
    <div className="w-full sm:w-1/2 md:w-1/3 p-2 flex justify-center">
      <div className={classes.card}>
        <img
          width={180}
          alt="img"
          src="https://interneturok.ru//static/img/lead-1.png"
        />
        <span className={classes.check}>
          <img
            alt="check"
            src="https://interneturok.ru//static/img/doubt-block-symb_check.svg"
          />
        </span>
        <h4 className="text-lg font-bold">Title</h4>
        <p className="text-gray-700 text-sm mt-4">
          We are constantly improving, updating, correcting lessons. Including
          based on the feedback provided by our users
        </p>
      </div>
    </div>
  );
};

export default Missing;
