import React from "react";
import { useGoogleMaps } from "react-hook-google-maps";
const Googlemap = () => {
  const { ref, map, google } = useGoogleMaps(
    //   Our Key
    "AIzaSyBJlza72Zw8tAFzC1NTXvPlQcHmbIZR5G4&libraries",
    {
      center: { lat: 0, lng: 0 },
      zoom: 3,
    }
  );
  return <div ref={ref} style={{ width: "100%", height: 500 }} />;
};

export default Googlemap;
