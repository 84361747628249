import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Missing from "./Missing";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f2f9ff",
    paddingBottom: 200,
  },
  wrapper: {
    maxWidth: 1000,
    margin: "auto",
  },
}));
const Missings = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className="flex flex-wrap">
          <Missing />
          <Missing />
          <Missing />
          <Missing />
          <Missing />
          <Missing />
        </div>
      </div>
    </div>
  );
};

export default Missings;
