import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "store/dialog";
const useStyles = makeStyles((theme) => ({
  dialogbox: {
    maxWidth: 500,
  },
  previewimg: {
    width: "100%",
    height: 400,
    [theme.breakpoints.down(700)]: {
      height: 200,
    },
  },
}));

const Preview = (props) => {
  const classes = useStyles();
  return (
    <img
      className={classes.previewimg}
      alt="s"
      src={`http://superadmin.nandwsouk.com/${props.value}`}
    />
  );
};

const Photos = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const Detail = useSelector((state) => state.Detail);
  const Modal = (val) => {
    dispatch(
      openDialog({
        children: <Preview value={val} />,
        fullWidth: true,
        fullScreen: false,
        scroll: "body",
        classes: { paper: `m-0  ${classes.dialogbox}` },
      })
    );
  };
  return (
    <div className="grid grid-cols-2 gap-4">
      {/* {!Detail.data.data.photos
        ? ""
        : Detail.data.data.photos.map((item, i) => {
            return (
              <div key={i}>
                <img
                  onClick={() => Modal(item.url)}
                  alt="s"
                  src={`http://superadmin.nandwsouk.com/${item.url}`}
                />
              </div>
            );
          })} */}
    </div>
  );
};

export default Photos;
