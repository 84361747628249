import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import Card from "../Card";

const useStyles = makeStyles((theme) => ({
  head: {
    fontSize: 21,
    [theme.breakpoints.down(750)]: {
      fontSize: 15,
    },
  },
  icon: {
    color: "#5CB85C",
    fontSize: 30,
    [theme.breakpoints.down(750)]: {
      fontSize: 15,
    },
  },
  td: {
    border: "1px solid #ced4da",
    width: 30,
    [theme.breakpoints.down(750)]: {
      width: 15,
    },
  },
  th: {
    textAlign: "center",
    [theme.breakpoints.down(750)]: {
      fontSize: 15,
      width: 20,
    },
  },
  root: {
    marginTop: "16px",
    overflow: "auto",
    "& .header": {
      fontSize: "24px",
      marginBottom: "16px",
    },
  },
}));

const Availability = ({ list }) => {
  const classes = useStyles();
  const Content = () => (
    <div className="cardcontent">
      <table className="text-left m-4 w-full">
        <tbody>
          <tr className={classes.head}>
            <th
              className={`p-4 bg-grey-lighter text-grey border-b border-grey-light text-center ${classes.head}`}
            >
              &nbsp;
            </th>
            {["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"].map((key, index) => (
              <th className={classes.th} key={index}>
                {key}
              </th>
            ))}
          </tr>

          {/* {Object.keys(list).map((key, index) => (             
            <tr className="availrow" key={index}>
              <th>{key}</th>
              {list[key]
                .split(",")
                .slice(0, 7)
                .map((child, i) => (
                  <td
                    key={i}
                    className={`py-4 px-6 border-b border-grey-light text-center ${classes.td}`}
                  >
                    {child === "1" ? (
                      <CheckCircleIcon className={classes.icon} />
                    ) : (
                      <RadioButtonUncheckedIcon className={classes.icon} />
                    )}
                  </td>
                ))}
            </tr>
          ))} */}
        </tbody>
      </table>
    </div>
  );
  return <Card Content={Content} className={classes.root} />;
};

export default Availability;
