import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  image: {
    height: 250,
    width: "100%",
    [theme.breakpoints.down(1400)]: {
      height: 200,
    },
    [theme.breakpoints.down(600)]: {
      display:"none"
    },
  },
  card: {
    width: 600,
    height: 450,
    [theme.breakpoints.down(1400)]: {
      width: 300,
      height: 400,
    },
    [theme.breakpoints.down(1000)]: {
      margin:"20px auto !important",
      width:400,
      height:400
    },
    [theme.breakpoints.down(600)]: {
      margin:"20px auto !important",
      width:"auto",
      height:"auto",
      boxShadow:"none !important"
    },
  },
}));
const ReasonsCard = (props) => {
  const classes = useStyles();
  return (
    <div
      className={`m-4 max-w-xs rounded overflow-hidden shadow-lg p-4 ${classes.card}`}
    >
      <img
        className={`${classes.image}`}
        src={props.image}
        alt="Student Card"
      />
      <div className="py-4">
        <div
          style={{ color: "rgb(22, 217, 152)" }}
          className="font-bold text-xl mb-2"
        >
          {props.title}
        </div>
        <p className="mt-6 text-gray-700 text-base leading-6">{props.text}</p>
      </div>
    </div>
  );
};

export default ReasonsCard;
