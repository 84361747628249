import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Title from "components/landing/Title";
import { Button } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 415,
    padding: 120,
    backgroundSize: "cover",
    backgroundPosition:"center",
    backgroundImage: "url(https://interneturok.ru//static/img/footer-top-1.jpg)",
    [theme.breakpoints.down(700)]:{
      padding: "50px 20px",
    }
  },
  wrapper: {
    maxWidth: 1000,
    margin: "auto",
  },
  box:{
      maxWidth:450,
      [theme.breakpoints.down(700)]:{
        textAlign:"center",
        maxWidth:"100%"
      }
  },
  registerBtn:{
      width:250,
      marginTop:30,
      height:40,
      backgroundColor:"#db2b21",
      color:"white",
      borderRadius:0,
      "&:hover":{
          backgroundColor:"#db2b21"
      }
  }
}));
const SeeBanner = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.box} >
        <Title black="head.see" blue="head.youself" />
        <p className="text-gray-700 text-lg mt-4" >
          Join 2.2 million students, parents and teachers already using the
          library "Internet Lesson"
        </p>
        <Button className={classes.registerBtn} >register</Button>
        </div>
      </div>
    </div>
  );
};

export default SeeBanner;
