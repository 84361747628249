import {
    SUBJECT_FAILED,
    SUBJECT_LOADING,
    SUBJECT_SUCCESS,
  } from "./subject.action";
  
  const initialState = {
    loading: false,
    data: [],
    error: null,
  };
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case SUBJECT_LOADING:
        return {
          ...state,
          loading: true,
        };
      case SUBJECT_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.data,
        };
      case SUBJECT_FAILED:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      default:
        return state;
    }
  };
  export default reducer;
  