import axios from "axios";

export const DETAIL_LOADING = "DETAIL_LOADING";
export const DETAIL_SUCCESS = "DETAIL_SUCCESS";
export const DETAIL_FAILED = "DETAIL_FAILED";

export const getDetailLoading = () => {
  return {
    type: DETAIL_LOADING,
  };
};

export const getDetailFailure = (error) => {
  return {
    type: DETAIL_FAILED,
    error,
  };
};
export const getDetailSuccess = (data) => {
  return {
    type: DETAIL_SUCCESS,
    data,
  };
};

export function getDetailAction(language, name) {
  const request = axios.get(
    `https://panel.eurasiaeco.ru/eda/v1/tutors-Profile?cityLocation=32.456456,56.6456456&SSIPD=5.63.13.165&lang=${language}&tutorsName=${name}`
  );
  return (dispatch) => {
    dispatch(getDetailLoading());
    request
      .then((response) => dispatch(getDetailSuccess(response.data)))
      .catch((err) => {
        dispatch(getDetailFailure());
      });
  };
}
