import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { Button } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  card: {
    height: 400,
    backgroundColor: "rgba(230,244,254)",
    margin: 50,
    boxShadow: "1px -1px 14px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down(900)]: {
      margin: 0,
    },
  },
  backdrops: {
    opacity: 0,
    transition: "0.3s",
  },
  box: {
    "&:hover": {
      "& $backdrops": {
        transition: "0.3s",
        opacity: 1,
      },
      "& $backdrops": {
        transition: "0.3s",
        opacity: 1,
      },
    },
  },
  icon: {
    position: "absolute",
    top: 20,
    left: 80,
  },
  badge: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    width: 50,
    height: 50,
    borderRadius: "50%",
    position: "absolute",
    left: 10,
    top: 10,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.14)",
  },
  name: {
    position: "absolute",
    bottom: 30,
    left: 0,
    margin: "auto",
    right: 0,
    textAlign: "center",
    color: "white",
    fontSize: 30,
    fontWeight: 700,
    textShadow: "0px 1px 2px rgba(0, 0, 0, 0.5)",
  },
  play: {
    backgroundColor: "rgba(0,0,0,0.6)",
    borderRadius: "50%",
    position: "absolute",
    margin: "auto",
    top: 0,
    width: 100,
    height: 100,
    bottom: 0,
    left: 0,
    right: 0,
  },
  playIcon: {
    margin: "auto",
    position: "absolute",
    color: "white",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: 50,
    height: 50,
  },
  showmore: {
    backgroundColor: "#db2b21",
    margin: "auto",
    color: "white",
    display: "block",
    borderRadius: 65,
    transition: "0.3s",
    padding: "15px 20px",
    "&:hover": {
      backgroundColor: "#db2b21",
      transition: "0.3s",
      padding: "17px 22px",
    },
  },
  arrowBtn: {
    backgroundColor: "white",
    borderRadius: "50%",
    width: 45,
    height: 45,
    position: "absolute",
    top: 0,
    transition: "300ms",
    "&:hover": {
      width: 49,
      height: 49,
      transition: "200ms",
    },
  },
  arrow: {
    width: 20,
    height: "60%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  },
  buttonGp: {
    width: 760,
    marginBottom: 30,
    opacity: 1,
    transition: "0.4s",
    height: 0,
    position: "absolute",
    left: 0,
    right: 0,
    margin: "auto",
    [theme.breakpoints.down(1600)]: {
      width: "50%",
      left: 50,
    },
    [theme.breakpoints.down(1500)]: {
      width: 760,
      left: 0,
    },
    [theme.breakpoints.down(900)]: {
      display: "none",
    },
  },

  carousel: {
    direction: "initial !important",
    position: "relative",
  },
  "@global": {
    ".react-multi-carousel-item": {
      display: "flex",
      justifyContent: "center",
      opacity: 0.2,
      opacity: "0.2 !important",
    },
    ".react-multi-carousel-item--active": {
      opacity: "1 !important",
    },
    ".react-multi-carousel-list": {
      direction: "initial",
    },
  },
}));

const ButtonGroup = ({ next, previous }) => {
  const classes = useStyles();
  return (
    <div className={classes.buttonGp}>
      <button
        style={{ left: 0 }}
        className={classes.arrowBtn}
        onClick={() => previous()}
      >
        <span className={classes.arrow}>
          <img
            alt="arrow"
            src="/static/img/swiper-button-next.svg"
          />
        </span>
      </button>
      <button
        style={{ right: 0 }}
        className={classes.arrowBtn}
        onClick={() => next()}
      >
        <span className={classes.arrow}>
          <img
            alt="arrow"
            src="/static/img/swiper-button-prev.svg"
          />
        </span>
      </button>
    </div>
  );
};

const Slider = (props) => {
  const classes = useStyles();
  const [showVideo, setshowVideo] = useState(false);
  const handleShowVideo = () => {
    setshowVideo(!showVideo);
  };

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const Lists = ["1", "2", "3", "4", "5"];
  return (
    <div>
      {width > 1500 ? (
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          customButtonGroup={
            <ButtonGroup next={props.next} previous={props.previous} />
          }
          draggable
          autoPlay={true}
          focusOnSelect={false}
          infinite
          centerMode={true}
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 1,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 1024,
                min: 0,
              },
              items: 1,
            },
          }}
          slidesToSlide={1}
          swipeable
        >
          {Lists.map((item, i) => {
            return (
              <div
                onClick={() => handleShowVideo(i)}
                className="cursor-pointer"
                key={i}
              >
                {!showVideo && (
                  <div
                    style={{ maxWidth: 820 }}
                    className={`relative ${classes.box}`}
                  >
                    <img
                      className={classes.card}
                      src="https://interneturok.ru/examples/poster_4_v2.jpg"
                    />
                    <div className={classes.backdrops}>
                      <div className={classes.icon}>
                        <div className={classes.badge}>
                          <img
                            width={30}
                            src="https://interneturok.ru/subject/no_background/subject-14.svg"
                            alt="badge"
                          />
                        </div>
                      </div>
                      <p className={classes.name}>peymanhc</p>
                      <button className={classes.play}>
                        <PlayArrowIcon className={classes.playIcon} />
                      </button>
                    </div>
                  </div>
                )}
                {showVideo && (
                  <video
                    className={classes.card}
                    poster="https://interneturok.ru/examples/poster_4_v2.jpg"
                    controls
                  >
                    <source src="" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            );
          })}
        </Carousel>
      ) : (
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          customButtonGroup={
            <ButtonGroup next={props.next} previous={props.previous} />
          }
          draggable
          autoPlay={false}
          focusOnSelect={false}
          infinite
          centerMode={false}
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 1,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 1024,
                min: 0,
              },
              items: 1,
            },
          }}
          slidesToSlide={1}
          swipeable
        >
          {Lists.map((item, i) => {
            return (
              <div onClick={handleShowVideo} className="cursor-pointer" key={i}>
                <div
                  style={{ maxWidth: 820 }}
                  className={`relative ${classes.box}`}
                >
                  <img
                    className={classes.card}
                    src="https://interneturok.ru/examples/poster_4_v2.jpg"
                  />
                  <div className={classes.backdrops}>
                    <div className={classes.icon}>
                      <div className={classes.badge}>
                        <img
                          width={30}
                          src="https://interneturok.ru/subject/no_background/subject-14.svg"
                          alt="badge"
                        />
                      </div>
                    </div>
                    <p className={classes.name}>peymanhc</p>
                    <button className={classes.play}>
                      <PlayArrowIcon className={classes.playIcon} />
                    </button>
                  </div>
                </div>
                {showVideo && (
                  <video
                    className={classes.card}
                    poster="https://interneturok.ru/examples/poster_4_v2.jpg"
                    controls
                  >
                    <source src="" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            );
          })}
        </Carousel>
      )}
      <Button className={classes.showmore}>Show More</Button>
    </div>
  );
};

export default Slider;
