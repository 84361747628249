import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import RepeatIcon from "@material-ui/icons/Repeat";
import TimerIcon from "@material-ui/icons/Timer";
import TelegramIcon from "@material-ui/icons/Telegram";
import Rating from "@material-ui/lab/Rating";
import { FormattedMessage } from "react-intl";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import Product from "./Product";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1150,
    margin: "auto",
  },
  CardBox: {
    width: "100%",
    height: 360,
    padding: "15px 0",
    display: "flex",
    backgroundColor: "white",
    boxShadow: "0 2px 4px 0 rgba(115,167,228,.4)",
    marginBottom: "1rem",
    position: "relative",
    color: "#232f49",
    [theme.breakpoints.down(750)]: {
      display: "block",
      height: "auto",
      margin: "auto",
      padding: 0,
      paddingBottom: 20,
      marginBottom: "1rem",
    },
  },
  imgProfile: {
    height: 170,
    objectFit: "cover",
    [theme.breakpoints.down(750)]: {
      display: "block",
      width: "100% !important",
      height: 280,
      padding: "0 !important",
    },
  },
  textbox: {
    [theme.breakpoints.down(750)]: {
      padding: 20,
      display: "block",
      width: "100% !important",
    },
  },
  name: {
    fontSize: 22,
    fontWeight: 700,
  },
  slogan: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: 600,
  },
  tag: {
    margin: "4px",
    padding: "7px",
    backgroundColor: "#ebeff2",
    fontSize: 12,
    color: "#545454",
    borderRadius: 8,
  },
  tagicon: {
    fontSize: 15,
  },
  text: {
    margin: "20px 0",
    fontSize: 14,
    color: "gray",
    lineClamp: 2,
    boxOrient: "vertical",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  price: {
    fontSize: 27,
    [theme.breakpoints.down(750)]: {
      position: "absolute",
      top: 20,
      right: 20,
      color: "white",
      textShadow: "2px 2px 2px black",
    },
  },
  star: {
    marginTop: 12,
    fontSize: 10,
    color: "#f8be15",
    [theme.breakpoints.down(750)]: {
      position: "absolute",
      top: 215,
      right: 0,
      left: 0,
      backgroundColor: "rgba(255, 255, 255, 0.24)",
      height: 40,
      alignItems: "center",
    },
  },
  viewBtn: {
    backgroundColor: "#db2b21",
    color: "white",
    width: "100%",
    height: 40,
    fontSize: 12,
    "&:hover": {
      backgroundColor: "#5aa75c",
    },
  },
  view: {
    [theme.breakpoints.down(750)]: {
      width: "100% !important",
    },
  },
}));
const Card = (props) => {
  const match = useRouteMatch({ path: "/:language/:city?" });
  const { language, city = "all-country" } = match.params;
  const currency = useSelector((state) => state.currency);
  const classes = useStyles();
  return (
    <div className={classes.CardBox}>
      <img
        className={`w-3/12 px-4 ${classes.imgProfile}`}
        alt="profile"
        // src={`${props.img.length>0 ? props.img[0].url:""}`}
        src={"/static/images/header/logo.png"}
      />
      <div className={`w-6/12 ${classes.textbox}`}>
        <h5 className={classes.name}>{props.name.substr(0, 30)}</h5>
        <h6 className={classes.slogan}>{props.slogan.substr(0, 50)}</h6>
        <p className={classes.text}>{props.text.substr(0, 50)}</p>

        <div className={`flex `}>
          {!props.product ? null : (
            <>
              {props.product.map((item, i) => {
                return (
                  <div key={i}>
                    <Product
                      img={item.images}
                      name={item.categoryLabelSlug}
                      text={item.categoryLabelId}
                      slogan={item.value}
                    />
                  </div>
                );
              })}
            </>
          )}
        </div>

        <div className={`flex flex-wrap`}>
          <span className={classes.tag}>
            <RepeatIcon className={classes.tagicon} />
            {props.repeat}
          </span>
          <span className={classes.tag}>
            <TimerIcon className={classes.tagicon} />
            {props.time}
          </span>
        </div>
      </div>
      <div className={`w-3/12 px-4 flex flex-col ${classes.view}`}>
        {!currency ? (
          "null"
        ) : (
          <h3 className={`flex justify-center ${classes.price}`}>
            {props.price === 0 ? (
              <b className="px-2 font-extrabold ">Free</b>
            ) : (
              <div>
                <b className="px-2 font-extrabold ">
                  {currency.current.symbol}
                  {props.price * currency.current.value}
                </b>
                / hr
              </div>
            )}
          </h3>
        )}
        <span className={`flex justify-center flex-1 ${classes.star}`}>
          <Rating precision={0.1} value={props.star} readOnly />
        </span>
        <Link
          className={classes.viewBtn}
          to={`/${language}/${city}/Suppliers/${props.slogan}/`}
        >
          <Button className={classes.viewBtn}>
            <FormattedMessage id="app.ViewProfile" />
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Card;
