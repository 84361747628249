import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Title from "components/landing/Title";
import Slider from "./Slider";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px 0 50px 0",
    background:
      "linear-gradient(0deg, rgba(230,244,254,0.9401961468181023) 32%, rgba(230,244,254,0.8085434857536764) 73%, rgba(255,255,255,0.28753508239233194) 100%);",
  },
  wrapper: {
    maxWidth: 1150,
    margin: "20px auto",
    textAlign: "center",
  },
}));

const VideoTutorials = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Title black="header.ExamplesvideoTutor" blue="header.tutorials" />
      </div>
      <Slider />
    </div>
  );
};

export default VideoTutorials;
