import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Title from "components/landing/Title";
import CardService from "./CardService";

const listItem = [
  {
    image: "https://interneturok.ru/_default_upload_bucket/opportunities-1.svg",
    title: "Video tutorial",
    text:
      "The most effective format of educational materials today is video, so learning a new topic begins with watching a video tutorial.",
  },
  {
    image: "https://interneturok.ru/_default_upload_bucket/opportunities-2.svg",
    title: "Interactive elements in the video tutorial",
    text:
      "The most effective format of educatThe most effective format of educatThe most effective format of educatThe most effective format of educatThe most effective format of educatThe most effective format of educatThe most effective format of educational materials today is video, so learning a new topic begins with watching a video tutori effective format of educatThe most effective format of educatThe most effective format of educatThe most effective format of educatThe most effective format of educatThe most effective format of educatThe most effective format of educational materials today is video, so learning a new topic begins with watching a video tutorial.",
  },
  {
    image: "https://interneturok.ru/_default_upload_bucket/opportunities-3.svg",
    title: "Test",
    text:
      "The most effective format of educational materials today is video, so learning a new topic begins with watching a video tutorial.",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  wrapper: {
    maxWidth: 1000,
    margin: "auto",
    "&:before": {
      content: "''",
      position: "absolute",
      backgroundImage:
        "url(https://interneturok.ru//static/img/lighter-color-wave.svg)",
      top: -80,
      width: "100%",
      height: 170,
      right: 0,
      left: 0,
    },
  },
  services: {
    padding: "50px 0",
    position: "relative",
  },
}));
const Services = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.services}>
          <div className="mb-10">
            <Title black="services.MoreAbout" blue="services.InternetLesson" />
          </div>
          {listItem.map((item, i) => {
            return (
              <div key={i}>
                <CardService
                  image={item.image}
                  title={item.title}
                  text={item.text}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Services;
