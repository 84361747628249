import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const List = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "Last"];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e5f3fe",
    marginTop: 20,
  },
  wrapper: {
    maxWidth: 1150,
    margin: "auto",
  },
  item: {
    justifyContent: "space-between",
    height: 250,
    fontSize: 70,
    fontWeight: 700,
    color: "#5899D6",
    cursor: "pointer",
    borderRadius: 10,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor: "white",
    alignItems: "center",
    margin: "5px 5px ",
    display: "flex",
    padding: 20,
    justifyContent: "center",
    borderBottom: "2px solid rgba(188, 218, 243, 0.32)",
    position:"relative",
    cursor:"pointer",
    transition:"0.3s",
    top:0,
    "&:hover":{
      top:-5,
      transition:"0.3s",
    }
  },

  libraryBox: {
    width: "100%",
    minHeight: 300,
    borderRadius: 12,
  },
}));
const LessenClasses = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div
          style={{ backgroundColor: props.bg, boxShadow: props.shadow }}
          className={classes.libraryBox}
        >
          <div class="grid grid-cols-3 gap-4">
            {List.map((item, i) => {
              return (
                <div key={i} className={classes.item}>
                  {item && item}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessenClasses;
