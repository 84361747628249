import {
  CATEGORY_FAILED,
  CATEGORY_LOADING,
  CATEGORY_SUCCESS,
} from "./searchcatgory.action";

const initialState = {
  loading: false,
  data: [],
  error: null,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CATEGORY_SUCCESS:
      return {
        error:null,
        loading: false,
        data: action.data,
      };
    case CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export default reducer;
