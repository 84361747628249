import { SUBJECT, LEVEL, WEEK, WHERE } from "./searchBySubjects.action";

const initialState = {
  subjects: "",
  level: "",
  where: "",
  week: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBJECT:
      return {
        ...state,
        subjects: action.subjects,
      };
    case LEVEL:
      return {
        ...state,
        level: action.level,
      };
    case WHERE:
      return {
        ...state,
        where: action.where,
      };
    case WEEK:
      return {
        ...state,
        week: action.week,
      };
    default:
      return state;
  }
};
export default reducer;
