export const SEARCH_VALUE = "SEARCH_VALUE";
export const SUBJECT_VALUE = "SUBJECT_VALUE";
export const CATEGORY_VALUE = "CATEGORY_VALUE";
export const PRICE_VALUE = "PRICE_VALUE";
export const RATE_VALUE = "RATE_VALUE";
export const PAGE_VALUE = "PAGE_VALUE";

const value = (value) => ({
  type: SEARCH_VALUE,
  value: value,
});

const subjectValue = (value) => ({
  type: SUBJECT_VALUE,
  subjectvalue: value,
});
const categoryvalue = (value) => ({
  type: CATEGORY_VALUE,
  categoryvalue: value,
});
const pricevalue = (value) => ({
  type: PRICE_VALUE,
  pricevalue: value,
});
const ratevalue = (value) => ({
  type: RATE_VALUE,
  ratevalue: value,
});
const pagevalue = (value) => ({
  type: PAGE_VALUE,
  pagevalue: value, 
});

export const SearchValueAction = (newstate) => {
  return (dispatch) => {
    dispatch(value(newstate));
  };
};
export const SubjectValueAction = (newstate) => {
  return (dispatch) => {
    dispatch(subjectValue(newstate));
  };
};
export const CategoryValueAction = (newstate) => {
  return (dispatch) => {
    dispatch(categoryvalue(newstate));
  };
};
export const PriceValueAction = (newstate) => {
  return (dispatch) => {
    dispatch(pricevalue(newstate));
  };
};
export const RateValueAction = (newstate) => {
  return (dispatch) => {
    dispatch(ratevalue(newstate));
  };
};
export const PageValueAction = (newstate) => {
  return (dispatch) => {
    dispatch(pagevalue(newstate));
  };
};
