import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Title from "./Title";
import Card from "./Card";
import { injectIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgb(251, 251, 255)",
  },
  wrapper: {
    maxWidth: 1000,
    margin: "auto",
    padding: "6em 0",
  },
  txt: {
    [theme.breakpoints.down(600)]: {
      width: "50%",
    },
  },
  showCaseTitle: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    margin: "32px 0px",
  },
  caseTitle: {
    fontSize: 25,
    fontWeight: 500,
    lineHeight: 1.3,
    [theme.breakpoints.down(600)]: {
      fontSize: 18,
    },
  },
}));
const TopCards = ({ intl }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Title
          title={intl.formatMessage({
            id: "Tutors.app.TopTutors.title",
            defaultMessage: "Top Tutors from",
          })}
        />
        <div className={`flex ${classes.showCaseTitle}`}>
          {intl.messages.ShowCasetitle.map((item, i) => (
            <div key={i} className={`p-6 text-center ${classes.txt}`}>
              <h5 className={classes.caseTitle}>{item.title}</h5>
              <span>{item.subtxt}</span>
            </div>
          ))}
        </div>
        <Card />
      </div>
    </div>
  );
};

export default injectIntl(TopCards);
