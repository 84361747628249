import axios from "axios";

export const COMMENTS_LOADING = "COMMENTS_LOADING";
export const COMMENTS_SUCCESS = "COMMENTS_SUCCESS";
export const COMMENTS_FAILED = "COMMENTS_FAILED";

export const getCommentsLoading = () => {
  return {
    type: COMMENTS_LOADING,
  };
};

export const getCommentsFailure = (error) => {
  return {
    type: COMMENTS_FAILED,
    error,
  };
};
export const getCommentsSuccess = (data) => {
  return {
    type: COMMENTS_SUCCESS,
    data,
  };
};

export function getCommentsAction(language, name) {
  const request = axios.get(
    `https://panel.eurasiaeco.ru/eda/v1/tutors-comments?SSIPD=5.63.13.165&lang=${language}&tutorsName=${name}`
  );
  return (dispatch) => {
    dispatch(getCommentsLoading());
    request
      .then((response) => dispatch(getCommentsSuccess(response.data)))
      .catch((err) => {
        dispatch(getCommentsFailure());
      });
  };
}
