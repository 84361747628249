import React from "react";
import HeadBanner from "components/abonement/HeadeBanner";
import ScrollTop from "components/landing/ScrollTop";
import Slider from "components/abonement/Slider";
import Info from "components/abonement/Info";
import Library from "components/abonement/Library";
import Services from "components/abonement/Services";
import Footer from "components/abonement/Footer";
function Abonement() {
  return (
    <div style={{ backgroundColor: "#F2F9FF" }}>
      <ScrollTop />
      <HeadBanner />
      <Slider />
      <Info />
      <Library />
      <Services />
      <Footer />
    </div>
  );
}

export default Abonement;
