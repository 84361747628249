import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  circleimg: {
    position: "relative",
    margin: "auto",
    [theme.breakpoints.down(1200)]: {
      left: 0,
      width: "50%",
      margin: "inherit",
    },
  },
  profileImg: {
    width: 110,
    height: 110,
    margin: "auto 100px",
    borderRadius: "50%",
    boxShadow: "0 2px 4px 0px rgba(115, 166, 227, 0.4)",
    border: "3px solid #ffffff",
  },
  price: {
    width: 65,
    height: 65,
    margin: "auto",
    backgroundColor: "#d50951",
    borderRadius: "50%",
    boxShadow: "0 2px 4px rgba(0, 97, 161, 0.86)",
    border: "3px solid #ffffff",
    position: "absolute",
    left: "22%",
    top: 45,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 14,
    fontWeight: 700,
    color: "white",
  },
}));

const ImgPriceBox = () => {
  const classes = useStyles();
  const Detail = useSelector((state) => state.Detail);
  const currency = useSelector((state) => state.currency);
  return (
    <div>
      {!Detail.data.data ? null : (
        <div className={classes.card}>
          <div className={`flex ${classes.cardhead}`}>
            <div className={classes.circleimg}>
              <img
                className={classes.profileImg}
                src={`http://superadmin.nandwsouk.com/admin/${Detail.data.data[0].masterImage}`}
              />
            </div>
            {Detail.data.data[0].PricePerHours === 0 ? (
              <div className={classes.price}>Free</div>
            ) : (
              <div className={classes.price}>
                {currency.current.symbol}
                {Detail.data.data[0].PricePerHours * currency.current.value}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImgPriceBox;
