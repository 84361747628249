import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button, Tooltip } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { PriceValueAction, RateValueAction } from "store/searchinput";
import { getLearners } from "store/bestTutors";
import { useRouteMatch } from "react-router-dom";
import { debounce } from "lodash";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  buttons: {
    margin: "0.5rem 1.25em",
    [theme.breakpoints.down(1200)]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  wrapper: {
    position: "absolute",
    top: 320,
    [theme.breakpoints.down(1200)]: {
      position: "relative",
      top: 0,
    },
  },
  button: {
    height: 40,
    margin: 5,
    backgroundColor: "white",
    border: "1px solid #232f49",
    color: "black",
    padding: "10px 25px",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      color: "black",
    },
  },
  rateButton: {
    height: 40,
    margin: 5,
    border: "1px solid white",
    color: "white",
    padding: "10px 25px",
    backgroundColor: "#232f49",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.7)",
    },
    [theme.breakpoints.down(1200)]: {
      backgroundColor: "white",
      border: "1px solid #232f49",
      color: "#232f49",
    },
  },
  paper: {
    backgroundColor: "white",
    boxShadow: "0 2px 4px 0 rgba(115,167,228,.4)",
    minHeight: 150,
    minWidth: 700,
    zIndex: 1,
    color: "black",
    padding: "10px 50px",
    position: "absolute",
    [theme.breakpoints.down(900)]: {
      minWidth: "auto",
      width: "auto",
    },
  },
  paperStar: {
    backgroundColor: "white",
    boxShadow: "0 2px 4px 0 rgba(115,167,228,.4)",
    minHeight: 150,
    minWidth: 400,
    zIndex: 1,
    color: "black",
    padding: "10px 50px",
    position: "absolute",
    [theme.breakpoints.down(900)]: {
      minWidth: "auto",
      width: "auto",
    },
  },
  apply: {
    backgroundColor: "#db2b21",
    color: "white",
    "&:hover": {
      backgroundColor: "#5aa85a",
    },
  },
}));
const AirbnbSlider = withStyles({
  root: {
    color: "#3a8589",
    height: 3,
    padding: "13px 0",
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    marginTop: -12,
    marginLeft: -13,
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    "& .bar": {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  track: {
    height: 3,
  },
  rail: {
    color: "#d8d8d8",
    opacity: 1,
    height: 3,
  },
})(Slider);

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const Buttons = () => {
  const classes = useStyles();
  useEffect(() => {
    setValue(value);
    window.scrollTo(0, 0);
    dispatch(getLearners());
    axios
      .get(
        "https://panel.eurasiaeco.ru/eda/generalSettings/WPAConfiguration?cityLocation=32.456456,56.6456456&SSIPD=5.63.13.165&lang=en&page=home"
      )
      .then((res) => {
        setmax(res.data.data ? res.data.data.priceRange[1]:'');
      });
  }, []);
  const [max, setmax] = useState();
  const [value, setValue] = React.useState([0, 12500000]);
  const [Star, setStar] = useState([0, 5]);
  const [price, setprice] = useState(false);
  const [Starval, setStarval] = useState(false);
  const dispatch = useDispatch();
  const match = useRouteMatch({ path: "/:language/:city?" });
  const { language, city = "all-country" } = match.params;
  const SearchVal = useSelector((state) => state.Searchvalue);
  const delayedCallback = debounce((value) => {
    dispatch(getLearners(value, language, city));
  }, 500);

  const StarSlider = (event, newValue) => {
    setStar(newValue);
    dispatch(RateValueAction(newValue));
    delayedCallback({
      searchText: SearchVal.value,
      subject: SearchVal.subjectvalue,
      category: SearchVal.categoryvalue,
      price: SearchVal.pricevalue,
      rate: newValue,
    });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(PriceValueAction(newValue));
    delayedCallback({
      searchText: SearchVal.value,
      subject: SearchVal.subjectvalue,
      category: SearchVal.categoryvalue,
      price: newValue,
      rate: SearchVal.ratevalue,
    });
  };
  const handleClear = () => {
    setValue(() => {
      const newstate = [0, max];
      dispatch(getLearners({ price: newstate }));
      return newstate;
    });
  };
  const handlePriceBtn = (event) => {
    event.preventDefault();
    setprice(true);
    document.addEventListener("click", closePriceMenu);
  };
  const closePriceMenu = () => {
    setprice(false);
    document.removeEventListener("click", closePriceMenu);
  };
  const handleStarBtn = (event) => {
    event.preventDefault();
    setStarval(true);
    document.addEventListener("click", closeStarMenu);
  };
  const closeStarMenu = () => {
    setStarval(false);
    document.removeEventListener("click", closeStarMenu);
  };
  return (
    <div style={{ maxWidth: 1150, margin: "auto" }}>
      <div className={classes.wrapper}>
        <div className={classes.buttons}>
          <Button onClick={handlePriceBtn} className={classes.button}>
            <FormattedMessage id="tutor.hero.price" defaultMessage="price" />
          </Button>
          <Button onClick={handleStarBtn} className={classes.rateButton}>
            <FormattedMessage id="tutor.hero.rate" defaultMessage="Rate" />
          </Button>
          {!price ? null : (
            <div className={classes.paper}>
              <p className="my-6">
                £{value[0]} - £{value[1]}/hr
              </p>
              <AirbnbSlider
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                ValueLabelComponent={ValueLabelComponent}
                max={max}
              />
              <div className="flex justify-between">
                <Button onClick={handleClear} className="mt-3">
                  Clear
                </Button>
                <Button
                  onClick={() => setprice(false)}
                  className={classes.apply}
                >
                  Apply
                </Button>
              </div>
            </div>
          )}

          {!Starval ? null : (
            <div className={classes.paperStar}>
              <p className="my-6">
                {Star[0]} - {Star[1]}
              </p>
              <AirbnbSlider
                value={Star}
                onChange={StarSlider}
                step={1}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                ValueLabelComponent={ValueLabelComponent}
                max={5}
              />
              <div className="flex justify-end">
                <Button
                  style={{ height: 40 }}
                  onClick={() => setStarval(false)}
                  className={classes.apply}
                >
                  Apply
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Buttons;
