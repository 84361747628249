import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/askQuestion/Card";
import Request from "components/askQuestion/Request";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage:
      "url(https://cdn.tutorful.co.uk/assets/backgrounds/tutorful-bg-shape.png)",
    backgroundColor: "white",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
  },
  wrapper: {
    maxWidth: 1200,
    margin: "auto",
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down(1050)]: {
      flexDirection: "column",
    },
  },
  card:{
    [theme.breakpoints.down(1050)]: {
      width:"100% !important"
    },
  },
  cardbox:{
    [theme.breakpoints.down(1050)]: {
      display:"none"
    },
  }
}));

function AskQuestion() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={`${classes.card} md:w-1/2 lg:w-2/3 p-4 md:px-16 md:p-8`}>
          <Request />
        </div>
        <div className={`${classes.cardbox} md:w-1/2 lg:w-1/3 p-8`}>
          <Card />
        </div>
      </div>
    </div>
  );
}

export default AskQuestion;
