import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { getLandingData } from "store/landing";
import AOS from "aos";
import "aos/dist/aos.css";
import Learner from "components/tutors/Learner";
import HeadBanner from "components/tutors/headbanner";
import StudentReasons from "components/tutors/StudentReasons";
import TopCards from "components/tutors/TopCards";
import Steps from "components/tutors/Steps";
import GetStart from "components/tutors/GetStart";

function Tutors({ intl }) {
  const reduxDispatch = useDispatch();
  const landingData = useSelector((state) => state.landing);
  //const categorise = useSelector(state => state.blog.leftOverCategories);
  const match = useRouteMatch({ path: "/:language" });
  const { language } = match.params;

  React.useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init(
      {
        duration: 1000,
      },
      []
    );
    reduxDispatch(getLandingData(language));
  }, [reduxDispatch, language]);

  return (
    <div className="landingWrapper">
      <HeadBanner />
      <StudentReasons />
      <TopCards />
      <Learner />
      <Steps />
      <GetStart />
    </div>
  );
}

export default injectIntl(Tutors);
