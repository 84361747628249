import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const useStyles = makeStyles((theme) => ({
  profileimg: {
    position: "relative",
    top: -80,
    width: 150,
    height: 150,
    borderRadius: "50%",
  },
  name: {
    fontSize: 20,
    fontWeight: 500,
  },
  username: {
    fontSize: 15,
    fontWeight: 400,
    color: "gray",
  },
  arrowBtn: {
    backgroundColor: "white",
    borderRadius: "50%",
    width: 45,
    height: 45,
    position: "absolute",
    top: 0,
    transition: "300ms",
    "&:hover": {
      width: 49,
      height: 49,
      transition: "200ms",
    },
  },
  arrow: {
    width: 20,
    height: "60%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  },
  buttonGp: {
    [theme.breakpoints.down(900)]: {
      display: "none",
    },
  },
  carousel: {
    direction: "initial !important",
    position: "relative",
  },
}));

const ButtonGroup = ({ next, previous }) => {
  const classes = useStyles();
  return (
    <div className={classes.buttonGp}>
      <button
        style={{ right: 360 }}
        className={classes.arrowBtn}
        onClick={() => previous()}
      >
        <span className={classes.arrow}>
          <img
            alt="arrow"
            src="/static/img/swiper-button-next.svg"
          />
        </span>
      </button>
      <button
        style={{ right: 300 }}
        className={classes.arrowBtn}
        onClick={() => next()}
      >
        <span className={classes.arrow}>
          <img
            alt="arrow"
            src="/static/img/swiper-button-prev.svg"
          />
        </span>
      </button>
    </div>
  );
};

const CardSlider = (props) => {
  const classes = useStyles(); 

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return (
    <div className="pb-8">
      <div className={classes.carousel}>
        {width < 900 ? (
        <Carousel
          additionalTransfrom={0}
          customButtonGroup={
            <ButtonGroup
              next={props.next}
              previous={props.previous}
            />
          }
          arrows={false}
          autoPlaySpeed={3000}
          className="pt-24"
          draggable
          focusOnSelect={false}
          infinite
          autoPlay={true}
          centerMode={false}
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 2,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 1024,
                min: 0,
              },
              items: 1,
            },
          }}
          slidesToSlide={1}
          swipeable
        >
          {props.data}
        </Carousel>
        ):         <Carousel
        additionalTransfrom={0}
        customButtonGroup={
          <ButtonGroup
            next={props.next}
            previous={props.previous}
          />
        }
        arrows={false}
        autoPlaySpeed={3000}
        className="pt-24"
        draggable
        autoPlay={true}
        focusOnSelect={false}
        infinite
        centerMode={true}
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: props.itemsToShow,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 1024,
              min: 0,
            },
            items: 1,
          },
        }}
        slidesToSlide={1}
        swipeable
      >
        {props.data}
      </Carousel>}
      </div>
    </div>
  );
};

export default CardSlider;
