import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  ScrollTop: {
    width: 60,
    height: 60,
    backgroundColor: "white",
    position: "fixed",
    bottom: 50,
    right: 50,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 100,
  },
}));

const ScrollTop = (props) => {
  const classes = useStyles();
  const [visible, setvisible] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", function (e) {
      toggleVisibility();
    });
  }, []);

  const toggleVisibility = () => {
    if (window.pageYOffset > 150) {
      setvisible(true);
    } else {
      setvisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {!visible ? (
        ""
      ) : (
        <button onClick={() => scrollToTop()} className={classes.ScrollTop}>
          <img
            alt="scrollTop"
            src="/static/img/uparrow.svg"
          />
        </button>
      )}
    </>
  );
};

export default ScrollTop;
