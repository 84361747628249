import React , {useState} from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles"; 
import GradeIcon from "@material-ui/icons/Grade";
import { FormattedMessage } from "react-intl";
import { useRouteMatch } from "react-router-dom";
import { Button, InputBase, NativeSelect, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({

  title: {
    width: "100%",
    [theme.breakpoints.down(1200)]: {
      margin: "auto",
      textAlign: "center",
      width: "auto",
    },
  },
  root: {
    width: "100%",
    height: 300,
    backgroundImage:
      "url(https://cdn.tutorful.co.uk/assets/backgrounds/tutorful-bg-shape-transparent.png)",
    backgroundColor: "#232f49",
    color: "white",
    [theme.breakpoints.down(750)]: {
      height: "auto",
    },
  },
  wrapper: {
    maxWidth: 1200,
    margin: "auto",
    display: "flex",
    padding: "100px 0",
    [theme.breakpoints.down(1250)]: {
      padding: "100px 20px",
    },
    [theme.breakpoints.down(750)]: {
      display: "block",
      maxWidth: 400,
      textAlign: "center",
    },
  },
  headTitle: {
    fontSize: "2.3rem",
    fontWeight: 800,
    [theme.breakpoints.down(750)]: {
      fontSize: "1.8rem",
    },
  },
  subTitle: {
    fontSize: 18,
    lineHeight: 1.5,
    [theme.breakpoints.down(750)]: {
      fontSize: "1rem",
      marginTop: 50,
    },
  },
  icons: {
    width: "35%",
    margin: "auto",
    color: "white",
    [theme.breakpoints.down(750)]: {
      width: "auto",
    },
  },
  logo: {
    width: 100,
  },
  starIcon: {
    color: "white",
    backgroundColor: "#00b67a",
    margin: "10px 2px",
  },
  score: {
    fontSize: 10,
    marginTop: 3,
  },
  widget: {
    width: 200,
    margin: "auto",
  },
  windjetItem: {
    [theme.breakpoints.down(750)]: {
      justifyContent: "center",
    },
  },
}));


const Input2 = withStyles((theme) => ({
  input: {
    padding: 17,
    width: 120,
    height:14,
    paddingRight: 20,
    color: "black",
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    borderRadius: 0,
    backgroundColor: "#db2b21",
    border: "1px solid #db2b21",
    fontSize: 16,
    "&:focus": {
      borderColor: "#db2b21",
      backgroundColor: "#db2b21",
      boxShadow: "0 0 0 0.1rem rgba(0,123,255,.25)",
    },
    [theme.breakpoints.down(350)]: {
      width: "auto",
    },
  },
}))(InputBase);


const HeadBanner = (props) => {
  const match = useRouteMatch({ path: "/:language/:city?" });
  const { language, city = "all-country" } = match.params;
  const classes = useStyles();
  // Redux
  const [selectvalue, setselectvalue] = useState("");

  const Subject = useSelector((state) => state.SearchSubject);
  const SearchVal = useSelector((state) => state.Searchvalue);
  const handleChange = (event) => {
    setselectvalue(event.target.value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.title}>
          <h2 className={classes.headTitle}>
            <FormattedMessage
              defaultMessage="Find your perfect tutor"
              id="Search.app.title"
            />
          </h2>
          <p className={classes.subTitle}>
            <FormattedMessage
              id="Search.app.subTitle"
              defaultMessage="Tell us what you Want"
            />
          </p>
          <div className={classes.fields}>
                <div className="flex py-4">
                  <input
                    className={classes.autoComplete}
                    type="text"
                   // onChange={props.searchByName}
                    value={SearchVal.data}
                    // placeholder={props.intl.formatMessage({
                    //   id: "home.hero.FindByName",
                    //   defaultMessage: "FindByName",
                    // })}
                  />
                  <NativeSelect
                    id="demo-customized-select-native"
                    value={selectvalue}
                    onChange={handleChange}
                    input={<Input2 />}
                  >
                    {/* <FormattedMessage id="app.default">
                      {(id) => (
                        <option onClick={props.subjectOnClick} value="">
                          {id}
                        </option>
                      )}
                    </FormattedMessage>
                    {!Subject.data.data
                      ? null
                      : Subject.data.data.map((item, i) => (
                        <option
                          key={i}
                          value={item.title}
                          onClick={props.subjectOnClick}
                        >
                          {language === "fa" ? item.translate.fa : ""}
                          {language === "ar" ? item.translate.ar : ""}
                          {language === "en" ? item.translate.en : ""}
                          {language === "ru" ? item.translate.ru : ""}
                        </option>
                      ))} */}
                  </NativeSelect>
                </div>
              </div>
        </div>
        <div className={classes.icons}>
          <div className={classes.widget}>
            <div className={`flex items-end sm:mt-5 ${classes.windjetItem}`}>
              <GradeIcon style={{ color: "#00b67a" }} />
              <p>
                <FormattedMessage
                  id="landing.app.header"
                  defaultMessage="Eurasia Eco"
                />
              </p>             
            </div>
            <div className={`flex items-end ${classes.windjetItem}`}>
              <GradeIcon className={classes.starIcon} />
              <GradeIcon className={classes.starIcon} />
              <GradeIcon className={classes.starIcon} />
              <GradeIcon className={classes.starIcon} />
              <GradeIcon className={classes.starIcon} />
            </div>
            <div className={`flex items-end ${classes.windjetItem}`}>
              <p className={classes.score}>
                <FormattedMessage
                  id="Search.app.Score"
                  defaultMessage="Eurasia Eco Score"
                />
                <b>4.8</b>
              </p>
            </div>
            <div className={`flex items-end ${classes.windjetItem}`}>
              <p className={classes.score}>
                <b>2350</b>
                <FormattedMessage
                  id="Search.app.reviews"
                  defaultMessage="reviews"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadBanner;
