import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Title from "./Title";
import { injectIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
  },
  wrapper: {
    maxWidth: 1000,
    margin: "auto",
    padding: "6em 0",
    position: "relative",
  },
  stepnumber: {
    height: 75,
    width: 75,
    borderRadius: 6,
    backgroundColor: "rgb(0, 204, 136)",
    position: "absolute",
    transform: "translateY(35%)",
  },
  stepnumberRight: {
    height: 90,
    width: 90,
    borderRadius: 6,
    backgroundColor: "rgb(0, 204, 136)",
    position: "absolute",
    right: 0,
    transform: "translateY(35%)",
  },
  number: {
    color: "rgb(80, 83, 102)",
    fontSize: 75,
    fontWeight: 600,
    textAlign: "right",
    transform: "translateY(-40%)",
    fontFamily: "Lato",
  },
  steps: {
    display: "flex",
    marginTop: "5em",
    [theme.breakpoints.down(600)]: {
      display: "block",
    },
  },
  steps2: {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: "5em",
    [theme.breakpoints.down(600)]: {
      display: "block",
    },
  },
  stepTitle: {
    width: "100%",
    fontSize: 28,
    fontWeight: 600,
    marginBottom: "1em",
    [theme.breakpoints.down(600)]: {
      fontSize: 20,
      marginTop:50
    },
  },
  stepbox: {
    padding: 10,
    marginBottom: 100,
    display: "flex",
  },
  stepbox2: {
    padding: 10,
    marginBottom: 100,
    display: "flex",
    flexDirection: "row-reverse",
  },
}));
const Steps = ({intl}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Title title={intl.formatMessage({
            id: "Tutors.app.steps.Title",
            defaultMessage: "Start learning in 3 simple steps",
          })} />
        {intl.messages.steps.map((item, i) => (
          <div
            key={i}
            data-aos="fade-up"
            className={item.id === 2 ? classes.stepbox2 : classes.stepbox}
          >
            <div className={classes.stepnumber}>
              <h2 className={classes.number}>{item.id}</h2>
            </div>
            <div className={item.id === 2 ? classes.steps2 : classes.steps}>
              <img className="w-full" alt="step1" src={item.image} />
              <div className="w-full flex items-center justify-center flex-col mx-4">
                <h4 className={classes.stepTitle}>{item.title}</h4>
                <p className="text-base text-gray-600 leading-6">{item.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default injectIntl(Steps);
