import axios from "axios";
export const VALUE = "VALUE";
const login = ({ code, mobile, email, verifyCode, pass,lang }) => ({
  type: VALUE,
  code: code,
  mobile: mobile,
  email: email,
  verifyCode: verifyCode,
  pass: pass,
  lang:lang
});
export const LoginAction = ({lang, code, mobile, email, verifyCode, pass }) => {
  return (dispatch) => {
    dispatch(login({ code, mobile, email, verifyCode, pass }));
    axios
      .post("https://www.superadmin.nandwsouk.com/api/auth/WPAAuthenticate", {
        lang,
        code,
        mobile,
        email,
        verifyCode,
        pass,
      })
      .then((res) => {
        alert(res.data.msgText)
      })
      .catch((err) => {
        alert(err.data.msgText)
      });
  };
};