import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    color: "#232f49",
    fontWeight: 800,
    marginTop: "3rem",
    textTransform: "capitalize",
  },
  subject: {
    fontSize: 15,
  },
}));

const Title = (props) => {
  const classes = useStyles();
  return <h4 className={classes.title}>{props.title}</h4>;
};

const Degree = () => {
  const classes = useStyles();
  const Detail = useSelector((state) => state.Detail);
  return (
    <div>
      {/* {!Detail.data.data
        ? null
        : Detail.data.data.Qualifications.map((item, i) => {
            return (
              <div key={i}>
                <Title title={item.title} />
                <div className="grid grid-cols-3 gap-4">
                  <p className={`mt-4 ${classes.subject}`}>{item.des}</p>
                  <p className={`mt-4 ${classes.subject}`}>{item.subtitle}</p>
                  <p className={`mt-4 ${classes.subject}`}>
                    status: {item.status}
                  </p>
                  <p className={`mt-4 ${classes.subject}`}>
                    date : {item.date}
                  </p>
                </div>
              </div>
            );
          })} */}
    </div>
  );
};

export default Degree;
