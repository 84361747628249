import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Title from "components/landing/Title";
import { Button } from "@material-ui/core";
import Libraries from "./Libraries";

const List = [
  {
    title: "math",
    icon: "https://interneturok.ru/subject/no_background/math.svg",
    lock: true,
    number: 20,
  },
  {
    title: "chimestry",
    icon: "https://interneturok.ru/subject/no_background/subject-2.svg",
    lock: false,
    number: 42,
  },
  {
    title: "Test",
    icon: "https://interneturok.ru/subject/no_background/subject-3.svg",
    lock: true,
    number: 17,
  },
  {
    title: "Test",
    icon: "https://interneturok.ru/subject/no_background/physics.svg",
    lock: false,
    number: 70,
  },
  {
    title: "poadp",
    icon: "https://interneturok.ru/subject/no_background/subject-8.svg",
    lock: false,
    number: 42,
  },
  {
    title: "Tesasdt",
    icon: "https://interneturok.ru/subject/no_background/subject-9.svg",
    lock: false,
    number: 137,
  },
  {
    title: "Test",
    icon: "https://interneturok.ru/subject/no_background/russian.svg",
    lock: false,
    number: 79,
  },
  {
    title: "poadp",
    icon: "https://interneturok.ru/subject/no_background/subject-14.svg",
    lock: false,
    number: 42,
  },
  {
    title: "Tesasdt",
    icon: "https://interneturok.ru/subject/no_background/subject-17.svg",
    lock: true,
    number: 137,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e5f3fe",
    padding: "4em",
    [theme.breakpoints.down(700)]:{
      padding: "1em",
 
    }
  },
  wrapper: {
    maxWidth: 1000,
    margin: "auto",
  },
  item: {
    margin: 20,
    paddingBottom: 20,
    borderBottom: "2px solid rgba(188, 218, 243, 0.32)",
  },
  libraryNumber: {
    fontSize: 35,
    fontWeight: 500,
    color: "rgb(16, 97, 216)",
  },
  libraryBox: {
    width: "100%",
    minHeight: 300,
    backgroundColor: "white",
    borderRadius: 12,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.14)",
    padding: 20,
  },
  lock: {
    width: 30,
    height: 30,
    margin: "0 5px",
    position: "relative",
    display: "inline-block",
    background: "#D5E9F9",
    borderRadius: "50%",
    verticalAlign: -8,
    marginLeft: 10,
    cursor: "pointer",
  },
  lockIcon: {
    backgroundImage:
      "url(https://interneturok.ru//static/img/subject-block.svg)",
    backgroundRepeat: "no-repeat",
    width: 15,
    backgroundSize: 15,
    height: 22,
    margin: "auto",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  number: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    fontWeight: 700,
    fontSize: 18,
    backgroundColor: "white",
    padding: "7px 20px",
    borderRadius: 30,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.14)",
    [theme.breakpoints.down(1000)]: {
      fontSize: 15,
      padding: "7px",
    },
  },
  list1: {
    borderRight: "2px dashed #BCDAF3",
    padding: "0 20px",
  },
  registerBtn: {
    backgroundColor: "#FE9023",
    color: "white",
    padding: "12px 0",
    transition: "0.3s",
    height: 60,
    width: 300,
    borderRadius: 70,
    "&:hover": {
      backgroundColor: "#FE9023",
      transition: "0.3s",
      width: 320,
    },
  },
  subscriptionBtn: {
    backgroundColor: "#1061d8",
    color: "white",
    padding: "12px 0",
    transition: "0.3s",
    height: 60,
    width: 300,
    borderRadius: 70,
    "&:hover": {
      backgroundColor: "#1061d8",
      transition: "0.3s",
      width: 320,
    },
  },
  btn: {
    width: 330,
    [theme.breakpoints.down(1000)]: {
      margin: "10px auto !important",
      width: "auto",
    },
  },
}));
const Library = () => {
  const classes = useStyles();
  const [Numbers, setNumbers] = useState([]);

  useEffect(() => {
    setNumbers(List.map((item, i) => item.number));
  }, []);
  const sum = Numbers.reduce((prevState, Numbers) => prevState + Numbers, 0);
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className="flex items-center justify-between mb-10">
          <Title black="header.QuantityTitle" blue="header.Internet" />
          <span className={classes.libraryNumber}>{sum}</span>
        </div>
        <div className="sm:px-16">
          <Libraries
            bg={"white"}
            shadow="0px 1px 2px rgba(0, 0, 0, 0.14)"
            shownumber={true}
            justify="space-between"
          />
        </div>
        <div className="block md:flex justify-center my-8">
          <div className={`flex justify-center ${classes.btn}`}>
            <Button className={classes.registerBtn}>Pay register</Button>
          </div>
          <div className={`flex justify-center ${classes.btn}`}>
            <Button className={classes.subscriptionBtn}>
              Pay for subscription
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Library;
